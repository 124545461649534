@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");
body {
    margin: 0;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.emptyPage {
    margin-top: 138px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 992px) {
        margin-top: 95px;
    }
    img {
        height: 283px;
        @media only screen and (max-width: 992px) {
            height: 213px;
        }
    }
    p {
        font-family: "Montserrat", Sans-serif;
    }
}

.about-shah-desktop {
    // position: relative;
    // background-color: blue;

    // _______________________________Nav bar_____________
    .logobutton {
        height: 90px;
        width: 100vw;
        background-color: white;
        display: flex;
        img {
            height: 64px;
            margin-top: 7px;
            margin-left: 120px;
        }
        .join {
            height: 16;
            width: 90px;
            //  background-color: red;
            font-family: "Montserrat", Sans-serif;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            letter-spacing: 0.5px;
            fill: #fff;
            color: #fff;
            background-color: #ff5722;
            border-radius: 3px 3px 3px 3px;
            padding: 16 30px 16 30px;
            margin-left: 750px;
            margin-top: 20px;
        }
    }
    .line {
        height: 1.2px;
        margin-top: -10px;
        background-color: rgba(44, 43, 43, 0.123);
        margin-left: 9%;
        margin-right: 9%;
    }
    .deskNav {
        display: grid;
        grid-template-columns: 1fr 1fr;
        // display: flex;
        height: 60px;
        width: 100vw;
        // background-color: yellowgreen;
        box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);
        -webkit-box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);
        -moz-box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);
        .navItem {
            display: flex;
            margin-left: 42px;
            // margin-top: 16;
            // // margin-right: 7%;
            // flex-direction: row;
            // align-items: center;
            // font-family: 'Montserrat', sans-serif;
            // font-size: 13px;

            position: absolute;
            li {
                list-style: none;
                // padding-top: 16;
                // padding-bottom: 16;
                font-size: 16;
                font-family: "Montserrat", sans-serif;
                font-weight: 500;
                cursor: pointer;
                margin-left: 40px;

                a {
                    text-decoration: none;
                    color: #000;
                }
                a:hover {
                    color: orangered;
                }
                ul {
                    opacity: 0;
                    position: absolute;
                    background-color: #ffffff;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    // align-items: center;
                    margin-top: 23px;
                    border-radius: 0px 0px 4px 4px;
                    width: 193px;
                    z-index: 1000;
                    li {
                        margin-left: -25px;
                        padding: 19px;
                        a:hover {
                            color: orangered;
                        }
                    }
                }
            }

            li:hover {
                ul {
                    opacity: 1;
                    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
                }
            }
        }
        .follow-us {
            display: flex;
            letter-spacing: 1.5px;
            margin-top: 16;
            margin-right: 10%;
            flex-direction: row;
            align-items: center;
            font-family: "Montserrat", sans-serif;
            font-size: 10px;

            position: absolute;
            right: 0;
            i {
                cursor: pointer;
                color: #ff5722;
                font-size: 17px;
                padding-left: 20px;
                // padding-right: 5px;
            }
        }
    }
    // __________________________________________

    .main-img {
        height: 480px;
        // width: 100vw;  black
        // background-color: red;
        position: relative;
        // z-index: 1;

        img {
            height: 380px;
            width: 100vw;
            opacity: 0.2;
            //  position: relative;
            //  z-index: 1;
        }

        .cover {
            height: 380px;
            // width: 100vw;  black
            background-color: black;
            position: absolute;
        }
        .us {
            position: absolute;
            margin-left: 600px;
            margin-top: 140px;
            h2 {
                color: #ffffff;
                font-family: "Montserrat", Sans-serif;
                font-size: 36px;
                font-weight: 600;
                line-height: 1.15em;
            }
            .flex {
                display: flex;
                color: white;
                margin-left: 20px;
                margin-top: -38px;

                h5 {
                    font-family: "Jost", Sans-serif;
                    font-size: 16;
                    font-weight: 300;
                    margin-right: 10px;
                }
                img {
                    height: 12px;
                    width: 12px;
                    margin-top: 30px;
                    margin-left: -4px;
                    margin-right: 4px;
                    //    position: absolute;
                    opacity: 1;
                }
            }
        }
    }
    .sai {
        height: 380px;
        // margin-top: -10px;
        margin-top: 83px;
        // background-color: yellow;
        display: flex;
        flex-direction: column;
        align-items: center;
        .om {
            color: #ff5722;
            font-family: "Montserrat", Sans-serif;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.15em;
        }
        .long {
            color: #ff5722;
            font-family: "Montserrat", Sans-serif;
            font-size: 1.5em;
            font-weight: 600;
            line-height: 1.15em;
            margin-bottom: 12px !important;
        }
        p {
            color: black;
            font-family: "Jost", Sans-serif;
            font-size: 16px;
        }
        .p1 {
            text-align: left;
        }
        .line1 {
            height: 1.2px;
            margin-top: 40px;
            background-color: rgba(44, 43, 43, 0.123);
            // background-color: black;
            margin-left: 9%;
            margin-right: 9%;
            // height: 200px;
            width: 80vw;
        }
    }
    .secpage {
        height: 900px;
        width: 100vw;
        // background-color: greenyellow;
        margin-top: -2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .long2 {
            color: #ff5722;
            font-family: "Montserrat", Sans-serif;
            font-size: 1.5em;
            font-weight: 600;
            line-height: 1.15em;
            margin-top: 21px;
        }
        .ul-1 {
            // background-color: hotpink;
            // font-weight: 500;

            .li-1 {
                color: orangered;
                font-family: "Montserrat", Sans-serif;
                font-size: 20px;
                line-height: 1.5;
                font-weight: 500;
                margin-top: 20px;
            }
            p {
                color: black;
                font-family: "Jost", Sans-serif;
                font-size: 16;
                line-height: 1.8;
            }
            .ul-2 {
                color: black;
                font-family: "Jost", Sans-serif;
                // font-size: 16;
                line-height: 1.5;
                font-weight: lighter !important;
                margin-top: 20px;
                .li-2 {
                    color: black;
                    font-family: "Jost", Sans-serif;
                    font-size: 16;
                    line-height: 1.8;

                    // margin-top: 20px;
                    list-style: none;
                }
                .li-2:hover {
                    color: #ff5722;
                }
            }
        }

        .lastblock {
            // height: 100px;
            // background-color: blue;
            display: flex;
            flex-direction: column;
            .pa1 {
                b {
                    color: black;
                    font-family: "Jost", Sans-serif;
                    font-size: 16;
                    font-weight: 400;
                }
                a {
                    color: #ff5722;
                    font-family: "Jost", Sans-serif;
                    margin-left: 8px;
                    font-size: 16;
                    font-weight: 400;
                    cursor: pointer;
                    opacity: 0.8;
                }
            }
        }

        .line1 {
            height: 40.6px;
            margin-top: 23px;
            background-color: rgba(44, 43, 43, 0.123);
            width: 80vw;
        }
    }

    .inception {
        // background-color: burlywood;
        display: flex;
        flex-direction: column;
        align-items: center;
        .line1 {
            height: 1.2px;
            // position: relative;
            margin-top: -1px;
            background-color: rgba(44, 43, 43, 0.123);
            // background-color: black;
            // margin-left: 9%;
            // margin-right: 9%;
            // height: 200px;
            width: 80vw;
        }
        .banner-shah {
            width: 100%;
            overflow: hidden;
            img {
                width: 100%;
                height: auto;
            }
        }
        .dark1 {
            color: #ff5722;
            font-family: "Montserrat", Sans-serif;
            font-size: 1.5em;
            font-weight: 600;
            line-height: 1.15em;
            margin-top: 30px;
            text-align: center;
        }

        .dark {
            color: #ff5722;
            font-family: "Montserrat", Sans-serif;
            font-size: 1.5em;
            font-weight: 600;
            line-height: 1.15em;
        }
        .allpara {
            color: black;
            // color: #FF5722;
            font-family: "Jost", Sans-serif;
            font-size: 16;
            font-weight: 400;
            line-height: 1.6;
            margin-top: -1em;
        }

        .both {
            height: 60px;
            width: 400px;
            // background: chartreuse;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 60px;
            .share {
                font-size: 16;
                margin-left: 50px;
                color: black;
                font-family: "Jost", Sans-serif;
                font-size: 16;
                font-weight: 300;
            }
            .social {
                font-size: 16;

                ul {
                    display: flex;
                    margin-left: -30px;
                    li {
                        list-style: none;
                        text-align: center;
                        text-decoration: none;
                        border-radius: 50% 50% 50% 50%;
                        width: 40px;
                        height: 40px;
                        line-height: 38px;
                        color: #ffffff;
                        background-color: #ff5722;
                        margin-right: 30px;
                    }
                }
            }
        }
    }

    .over {
        height: 433px;
        background-color: #abbcf1;
        opacity: 0.1;
        position: relative;
    }

    .con {
        height: 200px;
        width: 1200px;
        position: absolute;
        // background-color: #FF5722;
        opacity: 1 !important;
        margin-top: -310px;
        margin-left: 70px;
        display: flex;

        .firsty {
            height: 200px;
            width: 250px;
            // background-color: green;
            border-style: solid;
            border-width: 1px 1px 1px 1px;
            border-color: #8f99cd3b;
            border-radius: 30px 0px 30px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            margin-left: 40px;

            .ioco {
                height: 40px;
                width: 30px;
                margin-top: 40px;
                background-color: #ff5722;
                // margin-left: 30px;
                // margin-top: 30px;
            }
            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;

                .number-percentage-wraper {
                    color: black;
                    font-family: "Montserrat", Sans-serif;
                    font-size: 32px;
                    font-weight: 600;
                    .number-percentage {
                        margin-right: 10px;
                    }
                }
                .title {
                    color: black;
                    font-family: "Jost", Sans-serif;
                    font-size: 20px;
                    font-weight: 300;
                    margin-top: 6px;
                }
            }
        }
    }
    .lastsec {
        img {
            position: relative;
            height: 1000px;
            width: 100vw;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.07;
            // background-color:paleturquoise ;
        }

        .find {
            position: absolute;
            height: 640px;
            width: 480px;
            // background-color: #FF5722;
            margin-top: -750px;
            margin-left: 130px;
            h2 {
                color: black;
                font-family: "Montserrat", Sans-serif;
                font-size: 36px;
                font-weight: 600;
                line-height: 1.15em;
            }
            p {
                color: black;
                font-family: "Jost", Sans-serif;
                font-size: 16;
                font-weight: 300;
                line-height: 1.5;
            }
            ul {
                li {
                    color: black;
                    font-family: "Jost", Sans-serif;
                    font-size: 16;
                    font-weight: 300;
                    list-style: none;
                    line-height: 38px;
                }
            }
            .video-content {
                height: 80px;
                width: 300px;
                // background-color: paleturquoise;
                margin-left: 20px;
                display: flex;
                margin-top: 20px;
                .play {
                    height: 60px;
                    width: 60px;
                    background-color: #ff5722;
                    margin-left: 20px;
                    margin-top: 10px;
                    border-radius: 50%;
                    color: white;
                    i {
                        margin-top: 21px;
                        margin-left: 25px;
                        font-size: 20px;
                    }
                }
                .download {
                    height: 20px;
                    width: 20px;
                    background-color: yellow;
                    margin-left: 20px;
                    margin-top: 30px;
                }
                .linkk {
                    margin-left: 20px;
                    margin-top: 30px;
                    font-family: "Montserrat", Sans-serif;
                    font-size: 16;
                    font-weight: 500;
                }
                .linkk:hover {
                    color: #ff5722;
                }
            }
        }
        .aww {
            height: 200px;
            width: 200px;
            background-color: #ff5722;
            position: absolute;
            z-index: 1;
            margin-top: -400px;
            margin-left: 600px;
            border-radius: 30px 0px 30px 0px;

            .winner {
                display: flex;
                flex-direction: column;
                align-items: center;
                .ii {
                    // font-weight: 400;
                    font-size: 48px;
                    // font-family: elementskit!important;
                    // line-height: 1;
                    margin-bottom: 20px;
                    color: #ffffff;
                    margin-top: 30px;
                }
                .win {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: -5px;
                    .no {
                        color: #ffffff;
                        font-family: "IBM Plex Sans", Sans-serif;
                        font-size: 32px;
                        font-weight: 600;
                        margin-bottom: 10px;

                        span {
                            color: #ffffff;
                            font-family: "IBM Plex Sans", Sans-serif;
                            font-size: 32px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                    }
                    h3 {
                        color: #dbdbdb;
                        font-family: "Jost", Sans-serif;
                        font-size: 20px;
                        font-weight: 300;
                        margin-top: -5px;
                    }
                }
            }
        }

        .f-cover {
            img {
                position: absolute;
                height: 640.34px;
                width: 541.06px;
                opacity: 1;
                margin-top: -770px;
                margin-left: 700px;
                border-radius: 60px 0px 60px 0px;
            }
        }
    }

    .effect {
        background-image: url("../img/paral.jpg");
        // img{
        width: 100vw;
        height: 520px;
        background-attachment: fixed !important;
        // background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        // }
    }
    .say {
        width: 100vw;
        height: 170px;
        // background-color: palevioletred;
        // opacity: 1;
        position: absolute;
        z-index: 1;
        margin-top: -450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            color: white;
            // opacity: 1;
            font-family: "Montserrat", Sans-serif;
            font-size: 36px;
            font-weight: 600;
            line-height: 1.15em;
            margin-top: -2px;
        }

        p {
            display: flex;
            flex-direction: column;
            text-align: center;
            color: white;
            margin-top: -1px;
            font-family: "Jost", Sans-serif;
            font-size: 16;
            font-weight: 100;
        }
    }
    .cover-over {
        width: 100vw;
        height: 520px;
        background-color: black;
        position: absolute;
        margin-top: -520px;
        opacity: 0.7;
    }
    .slider {
        width: 100vw;
        height: 360px;
        // background-color :rgb(0, 145, 242);
        position: absolute;
        margin-top: -260px;
        // opacity: 0.7;
        display: flex;
        padding-left: 50px;
        .boxy {
            width: 360px;
            height: 360px;
            background-color: white;
            margin-left: 40px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            text-align: center;
            box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
            -webkit-box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
            -moz-box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);

            .cardy {
                margin-top: 40px;
                .picy {
                    img {
                        height: 85px;
                        width: 85px;
                        border-radius: 50%;
                    }
                }
                .profile-info {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    .author-name {
                        margin-top: 20px;
                        color: black;
                        font-family: "Montserrat", Sans-serif;
                        font-size: 16;
                        font-weight: 600;
                    }
                    .author-des {
                        font-family: "Montserrat", Sans-serif;
                        font-size: 17px;
                        font-weight: 300;
                        color: #646a7a;
                        margin-top: -5px;
                    }
                }
                .commentor-content {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    ul {
                        list-style: none;
                        display: flex;
                        margin-left: 80px;

                        li {
                            color: #ff5722;
                            font-weight: 900;
                            line-height: 1;
                            font-size: 20px;
                            margin-right: 8px;
                        }
                    }
                    p {
                        font-size: 16px;
                        font-family: "jost", sans-serif;
                        font-weight: 300;
                        line-height: 1.6em;
                        color: black;
                    }
                }
            }
        }
    }

    .news {
        width: 100vw;
        height: 300px;
        // background-color :red;
        background-color: white;
        // margin-top: 50px;
    }
    .real-news {
        width: 80vw;
        height: 175px;
        background-color: #ffffff;
        position: absolute;
        margin-top: -60px;
        margin-left: 140px;
        border-radius: 5px;
        box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
        -webkit-box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
        -moz-box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
    }
    .lasty {
        width: 100vw;
        height: 700px;
        background-color: black;
    }
}

.about-shah-mobile {
    overflow: hidden;
    // position: relative;
    // background-color: blue;
    height: auto;

    .om {
        color: #ff5722;
        font-family: "Montserrat", Sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.15em;
        text-align: center;
        margin-top: 12%;
    }
    .long {
        color: #ff5722;
        font-family: "Montserrat", Sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.15em;
        margin-left: 23px;
        margin-right: 23px;
        text-align: center;
    }
    p {
        color: black;
        font-family: "Jost", Sans-serif;
        font-size: 16;
        // font-weight: 100;
        line-height: 1.5;
        opacity: 0.7;
        margin-left: 23px;
        margin-right: 23px;
        text-align: justify;
        margin-bottom: 23px;
    }
    .p1 {
        text-align: left;
    }
    .line1 {
        height: 1.2px;

        margin-top: 40px;
        background-color: rgba(44, 43, 43, 0.123);
        // background-color: black;
        margin-left: 9%;
        margin-right: 9%;
        // height: 200px;
        width: 80vw;
    }
}
.secpage {
    height: 900px;
    width: 100vw;
    // background-color: greenyellow;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    .long2 {
        color: #ff5722;
        font-family: "Montserrat", Sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.15em;
        margin-top: -1px;
    }
    .ul-1 {
        // background-color: hotpink;
        // font-weight: 500;

        .li-1 {
            color: orangered;
            font-family: "Montserrat", Sans-serif;
            font-size: 20px;
            line-height: 1.5;
            font-weight: 500;
            margin-top: 20px;
        }
        p {
            color: black;
            font-family: "Jost", Sans-serif;
            font-size: 16;
            line-height: 1.8;
        }
        .ul-2 {
            color: black;
            font-family: "Jost", Sans-serif;
            font-size: 16;
            line-height: 1.5;
            // font-weight: lighter !important;
            margin-top: 20px;
            margin-left: -53px;
            .li-2 {
                color: black;
                font-family: "Jost", Sans-serif;
                font-size: 16;
                line-height: 1.8;
                margin-left: 1.5em;
                // margin-top: 20px;
                list-style: none;
                a {
                    text-decoration: none;
                    color: black;
                }
            }
            .li-2:hover {
                color: #ff5722;
            }
        }
    }

    .lastblock {
        height: 100px;
        // background-color: blue;

        .pa1 {
            b {
                color: black;
                font-family: "Jost", Sans-serif;
                font-size: 16px;
                font-weight: 400;
            }

            color: black;
            a {
                color: orangered;
                font-family: "Jost", Sans-serif;
                margin-left: 8px;
                font-size: 16px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }

    .line1 {
        height: 1.2px;
        // position: relative;
        margin-top: -1px;
        background-color: rgba(44, 43, 43, 0.123);
        // background-color: black;
        // margin-left: 9%;
        // margin-right: 9%;
        // height: 200px;
        width: 80vw;
    }
}
.inception-mobile {
    // background-color: burlywood;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 796px;
    .banner-shah {
        width: 100%;
        img {
            width: 100%;
            height: auto;
            margin-bottom: 1em;
        }
    }
    .dark {
        color: #ff5722;
        font-family: "Montserrat", Sans-serif;
        font-size: 1.5em;
        font-weight: 600;
        line-height: 1.15em;
        margin-top: -8em;
        text-align: center;
    }
    .allpara {
        color: black;
        font-family: "Jost", Sans-serif;
        font-size: 16;
        font-weight: 400;
        line-height: 1.6;
    }
    .both {
        height: 60px;
        width: 400px;
        // background: chartreuse;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 60px;
        .share {
            font-size: 16;
            margin-left: 50px;
            color: black;
            font-family: "Jost", Sans-serif;
            font-size: 16;
            font-weight: 300;
        }
        .social {
            font-size: 16;

            ul {
                display: flex;
                margin-left: -30px;
                li {
                    list-style: none;
                    text-align: center;
                    text-decoration: none;
                    border-radius: 50% 50% 50% 50%;
                    width: 40px;
                    height: 40px;
                    line-height: 38px;
                    color: #ffffff;
                    background-color: #ff5722;
                    margin-right: 30px;
                }
            }
        }
    }
}

.over {
    height: 433px;
    background-color: #abbcf1;
    opacity: 0.1;
    position: relative;
}

.con {
    height: 200px;
    width: 1200px;
    position: absolute;
    // background-color: #FF5722;
    opacity: 1 !important;
    margin-top: -310px;
    margin-left: 70px;
    display: flex;

    .firsty {
        height: 200px;
        width: 250px;
        // background-color: green;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #8f99cd3b;
        border-radius: 30px 0px 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        margin-left: 40px;

        .ioco {
            height: 40px;
            width: 30px;
            margin-top: 40px;
            background-color: #ff5722;
            // margin-left: 30px;
            // margin-top: 30px;
        }
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;

            .number-percentage-wraper {
                color: black;
                font-family: "Montserrat", Sans-serif;
                font-size: 32px;
                font-weight: 600;
                .number-percentage {
                    margin-right: 10px;
                }
            }
            .title {
                color: black;
                font-family: "Jost", Sans-serif;
                font-size: 20px;
                font-weight: 300;
                margin-top: 6px;
            }
        }
    }
}
.lastsec {
    img {
        position: relative;
        height: 1000px;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.07;
        // background-color:paleturquoise ;
    }

    .find {
        position: absolute;
        height: 640px;
        width: 480px;
        // background-color: #FF5722;
        margin-top: -750px;
        margin-left: 130px;
        h2 {
            color: black;
            font-family: "Montserrat", Sans-serif;
            font-size: 36px;
            font-weight: 600;
            line-height: 1.15em;
        }
        p {
            color: black;
            font-family: "Jost", Sans-serif;
            font-size: 16;
            font-weight: 300;
            line-height: 1.5;
        }
        ul {
            li {
                color: black;
                font-family: "Jost", Sans-serif;
                font-size: 16;
                font-weight: 300;
                list-style: none;
                line-height: 38px;
            }
        }
        .video-content {
            height: 80px;
            width: 300px;
            // background-color: paleturquoise;
            margin-left: 20px;
            display: flex;
            margin-top: 20px;
            .play {
                height: 60px;
                width: 60px;
                background-color: #ff5722;
                margin-left: 20px;
                margin-top: 10px;
                border-radius: 50%;
                color: white;
                i {
                    margin-top: 21px;
                    margin-left: 25px;
                    font-size: 20px;
                }
            }
            .download {
                height: 20px;
                width: 20px;
                background-color: yellow;
                margin-left: 20px;
                margin-top: 30px;
            }
            .linkk {
                margin-left: 20px;
                margin-top: 30px;
                font-family: "Montserrat", Sans-serif;
                font-size: 16;
                font-weight: 500;
            }
            .linkk:hover {
                color: #ff5722;
            }
        }
    }
    .aww {
        height: 200px;
        width: 200px;
        background-color: #ff5722;
        position: absolute;
        z-index: 1;
        margin-top: -400px;
        margin-left: 600px;
        border-radius: 30px 0px 30px 0px;

        .winner {
            display: flex;
            flex-direction: column;
            align-items: center;
            .ii {
                // font-weight: 400;
                font-size: 48px;
                // font-family: elementskit!important;
                // line-height: 1;
                margin-bottom: 20px;
                color: #ffffff;
                margin-top: 30px;
            }
            .win {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: -5px;
                .no {
                    color: #ffffff;
                    font-family: "IBM Plex Sans", Sans-serif;
                    font-size: 32px;
                    font-weight: 600;
                    margin-bottom: 10px;

                    span {
                        color: #ffffff;
                        font-family: "IBM Plex Sans", Sans-serif;
                        font-size: 32px;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                }
                h3 {
                    color: #dbdbdb;
                    font-family: "Jost", Sans-serif;
                    font-size: 20px;
                    font-weight: 300;
                    margin-top: -5px;
                }
            }
        }
    }

    .f-cover {
        img {
            position: absolute;
            height: 640.34px;
            width: 541.06px;
            opacity: 1;
            margin-top: -770px;
            margin-left: 700px;
            border-radius: 60px 0px 60px 0px;
        }
    }
}

.effect {
    background-image: url("../img/paral.jpg");
    // img{
    width: 100vw;
    height: 520px;
    background-attachment: fixed !important;
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    // }
}
.say {
    width: 100vw;
    height: 170px;
    // background-color: palevioletred;
    // opacity: 1;
    position: absolute;
    z-index: 1;
    margin-top: -450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        color: white;
        // opacity: 1;
        font-family: "Montserrat", Sans-serif;
        font-size: 36px;
        font-weight: 600;
        line-height: 1.15em;
        margin-top: -2px;
    }

    p {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: white;
        margin-top: -1px;
        font-family: "Jost", Sans-serif;
        font-size: 16;
        font-weight: 100;
    }
}
.cover-over {
    width: 100vw;
    height: 520px;
    background-color: black;
    position: absolute;
    margin-top: -520px;
    opacity: 0.7;
}
.slider {
    width: 100vw;
    height: 360px;
    // background-color :rgb(0, 145, 242);
    position: absolute;
    margin-top: -260px;
    // opacity: 0.7;
    display: flex;
    padding-left: 50px;
    .boxy {
        width: 360px;
        height: 360px;
        background-color: white;
        margin-left: 40px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        text-align: center;
        box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
        -webkit-box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
        -moz-box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);

        .cardy {
            margin-top: 40px;
            .picy {
                img {
                    height: 85px;
                    width: 85px;
                    border-radius: 50%;
                }
            }
            .profile-info {
                display: flex;
                flex-direction: column;
                text-align: center;
                .author-name {
                    margin-top: 20px;
                    color: black;
                    font-family: "Montserrat", Sans-serif;
                    font-size: 16;
                    font-weight: 600;
                }
                .author-des {
                    font-family: "Montserrat", Sans-serif;
                    font-size: 17px;
                    font-weight: 300;
                    color: #646a7a;
                    margin-top: -5px;
                }
            }
            .commentor-content {
                display: flex;
                flex-direction: column;
                text-align: center;
                ul {
                    list-style: none;
                    display: flex;
                    margin-left: 80px;

                    li {
                        color: #ff5722;
                        font-weight: 900;
                        line-height: 1;
                        font-size: 20px;
                        margin-right: 8px;
                    }
                }
                p {
                    font-size: 16px;
                    font-family: "jost", sans-serif;
                    font-weight: 300;
                    line-height: 1.6em;
                    color: black;
                }
            }
        }
    }
}

.news {
    width: 100vw;
    height: 300px;
    // background-color :red;
    background-color: white;
    // margin-top: 50px;
}
.real-news {
    width: 80vw;
    height: 175px;
    background-color: #ffffff;
    position: absolute;
    margin-top: -60px;
    margin-left: 140px;
    border-radius: 5px;
    box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
    -webkit-box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
    -moz-box-shadow: 2px 2px 20px 4px rgba(128, 128, 128, 0.23);
}
.lasty {
    width: 100vw;
    height: 700px;
    background-color: black;
}




