@import '../../styles/mediaQuery.scss';
.main-course-detail-page {
  .course-details-page {
    padding: 1em 3em;
    @include responsive(phone) {
      padding: 1em 1em;
    }
    .teacher-section {
      padding: 0em 1em;
      @include responsive(phone) {
        padding: 1em 0;
      }
      .author-reviewcontent {
        margin-top: 1.5em;
        padding-top: 0.5em;
        display: flex;
        @include responsive(phone) {
          flex-wrap: wrap;
          justify-content: center;
        }
        .image-section {
          .image-container {
            height: 100px;
            width: 100px;
            overflow: hidden;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .reviewcontent {
          margin-left: 1em;
          width: 60%;
          text-align: justify;
          .instructor {
            .teacher {
              font-family: 'Jost', sans-serif;
              font-size: 1.4em;
              font-weight: 600;
            }
            @include responsive(phone) {
              text-align: center;
            }
          }
          @include responsive(tab-port) {
            width: 75%;
          }
          @include responsive(phone) {
            width: 90%;
            margin-left: 0em;
          }
          .author-data {
            .name {
              font-family: 'Jost', sans-serif;
              font-weight: 600;
              @include responsive(phone) {
                text-align: center;
              }
            }
            .author-bio {
              padding-top: 0.3em;
              font-family: 'Jost', sans-serif;
              @media (min-width: 992px) and (max-width: 1050px) {
                text-align: justify;
                width: 400px;
              }
              @media (min-width: 1050px) and (max-width: 1280px) {
                text-align: justify;
                width: 500px;
              }
            }
          }
        }
      }
    }
    .group-save {
      margin-top: 7em;
      margin-left: calc(-24% - 70px);
      @include responsive(phone) {
        margin-top: 2em;
      }
      // @media (min-width: 992px) and (max-width: 1050px) {
      //   width: 500px;
      //   margin-left: 0;
      // }
      // @media (min-width: 1050px) and (max-width: 1280px) {
      //   width: 600px;
      //   margin-left: 0;
      // }
      display: flex;
      justify-content: center;
      .box {
        border: 1px solid orangered;
        padding: 2em 3em;
        border-radius: 6px;
        width: calc(50% - 10%);
        @include responsive(phone) {
          padding: 1em 1em;
        }
        .top-section {
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          font-size: 1.3em;
          text-align: center;
        }
        .para {
          margin-top: 1em;
          font-family: 'Jost', sans-serif;
          text-align: center;
        }
        .group-access-link {
          margin-top: 1em;
          text-align: center;
          font-family: 'Jost', sans-serif;
          a {
            text-decoration: none;
            text-decoration: underline;
            color: orangered;
            font-size: 0.9em;
            font-weight: 600;
          }
        }
      }
    }

    .courses {
      // margin-top: 5em;
      margin-bottom: -11em;
      @include responsive(phone) {
        margin-top: 2em;
      }
      .courses-heading {
        padding: 0em 0 0.5em 1em;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 1.4em;
        @include responsive(phone) {
          padding: 0;
        }
      }
      .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}
