.app-course-index{
    background-color: #F2F6F9;
    height: 100vh;
    overflow-x: hidden;
    .app-nav-course-index{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 2em;
        font-family: 'Jost', sans-serif;
        position: relative;
        .back-btn{
            position: absolute;
            left: 0;
            margin-left: 1.4em;
            font-size: 16px;
            cursor: pointer;
        }
        .course-app-title{
            div{
                width: 263px;
                text-align: center;
            }
        }

    }
    .video-shah-app{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Jost', sans-serif;
        margin-top: 2em;
        iframe{
            height: 320px;
            width: 320px;
            border-radius: 7px;
            margin-bottom: 19px;

        }
        .web-course{
            color: orangered;
            cursor: pointer;
        }
    }

    .content-background{
        background-color:#F9FDFF ;
        margin-top: 2em;
        .title{
            font-family: 'Jost',sans-serif;
            margin-left: 19px;
            width: 100%;
            font-weight: 500;
            display: none;

            span{
                position: absolute;
                color: orangered;
                right: 0;
                margin-right: 23px;
                display: none;
            }
        }
    }
    .grid-card{
        
        margin-top: 30px;
        margin-bottom: 123px;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;

        .card{
            background-color: #fff;
            height: 274px;
            width: 297px;
            border-radius: 12px;
            border: 2px solid rgb(182, 179, 179);
            overflow: hidden;
            margin-left: 5px;
            margin-right: 10px;
            margin-top:20px;
            .image{
                background-color: #ffffff;
                height: 70%;
                border-radius: 12px;
                border-bottom-left-radius:0px ;
                border-bottom-right-radius:0px ;
                overflow:hidden;
                img{
                    height: auto;
                    width: 100%;
                }
            }
            .title-card{
                background-color: #fff;
                font-family: 'Jost', sans-serif;
                font-size: 13px;
                height: 20%;
                overflow: hidden;
                padding-left: 12px;
                padding-right: 12px;
                padding-top: 2px;

            }
            .contents{
                background-color: #fff;
                font-family: 'Jost', sans-serif;
                font-size: 13px;
                padding-left: 12px;
                padding-right: 12px;
                margin-top: -7px;
            }
        }
    }
   
}