@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");

body:has(.SplashScreen) {
  overflow: hidden;
}


.blurImg {
  filter: blur(1px);
}

.payment-box {
  text-align: center;
  font-family: jost, sans-serif;
  border: 2px solid orangered;
  padding-bottom: 30px;
  padding-top: 37px;
  border-radius: 5px;
  border-top: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: -1em;
}

.blink-hard {
  animation: blinker 1s step-end infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


h1 {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h2 {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h3 {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h4 {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div {
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.player {
  height: auto;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.emptyPage {
  margin-top: 138px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .emptyPage {
    margin-top: 95px;
  }
}

.emptyPage img {
  height: 283px;
}

@media only screen and (max-width: 992px) {
  .emptyPage img {
    height: 213px;
  }
}

.emptyPage p {
  font-family: "Montserrat", Sans-serif;
}

.about-shah-desktop .logobutton {
  height: 90px;
  width: 100vw;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about-shah-desktop .logobutton img {
  height: 64px;
  margin-top: 7px;
  margin-left: 120px;
}

.about-shah-desktop .logobutton .join {
  height: 15px;
  width: 90px;
  font-family: "Montserrat", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.5px;
  fill: #fff;
  color: #fff;
  background-color: #ff5722;
  border-radius: 3px 3px 3px 3px;
  padding: 15px 30px 15px 30px;
  margin-left: 750px;
  margin-top: 20px;
}

.about-shah-desktop .line {
  height: 1.2px;
  margin-top: -10px;
  background-color: rgba(44, 43, 43, 0.123);
  margin-left: 9%;
  margin-right: 9%;
}

.about-shah-desktop .deskNav {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  height: 60px;
  width: 100vw;
  box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);
  -webkit-box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);
  -moz-box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);
}

.about-shah-desktop .deskNav .navItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 42px;
  position: absolute;
}

.about-shah-desktop .deskNav .navItem li {
  list-style: none;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  cursor: pointer;
  margin-left: 40px;
}

.about-shah-desktop .deskNav .navItem li a {
  text-decoration: none;
  color: #000;
}

.about-shah-desktop .deskNav .navItem li a:hover {
  color: orangered;
}

.about-shah-desktop .deskNav .navItem li ul {
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 23px;
  border-radius: 0px 0px 4px 4px;
  width: 193px;
  z-index: 1000;
}

.about-shah-desktop .deskNav .navItem li ul li {
  margin-left: -25px;
  padding: 19px;
}

.about-shah-desktop .deskNav .navItem li ul li a:hover {
  color: orangered;
}

.about-shah-desktop .deskNav .navItem li:hover ul {
  opacity: 1;
  -webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.about-shah-desktop .deskNav .follow-us {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  letter-spacing: 1.5px;
  margin-top: 15px;
  margin-right: 10%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  position: absolute;
  right: 0;
}

.about-shah-desktop .deskNav .follow-us i {
  cursor: pointer;
  color: #ff5722;
  font-size: 17px;
  padding-left: 20px;
}

.about-shah-desktop .main-img {
  height: 480px;
  position: relative;
}

.about-shah-desktop .main-img img {
  height: 380px;
  width: 100vw;
  opacity: 0.2;
}

.about-shah-desktop .main-img .cover {
  height: 380px;
  background-color: #011633;
  position: absolute;
}

.about-shah-desktop .main-img .us {
  position: absolute;
  margin-left: 600px;
  margin-top: 140px;
}

.about-shah-desktop .main-img .us h2 {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.15em;
}

.about-shah-desktop .main-img .us .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: white;
  margin-left: 20px;
  margin-top: -38px;
}

.about-shah-desktop .main-img .us .flex h5 {
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-right: 10px;
}

.about-shah-desktop .main-img .us .flex img {
  height: 12px;
  width: 12px;
  margin-top: 30px;
  margin-left: -4px;
  margin-right: 4px;
  opacity: 1;
}

.about-shah-desktop .sai {
  margin-top: 83px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-shah-desktop .sai .om {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.15em;
}

.about-shah-desktop .sai .long {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.15em;
  margin-top: 4px;
  margin-bottom: 23px;
}

.about-shah-desktop .sai .para {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  padding: 0em 4em;
  text-align: justify;
}

.about-shah-desktop .sai .line1 {
  height: 1.2px;
  margin-top: 40px;
  background-color: rgba(44, 43, 43, 0.123);
  margin-left: 9%;
  margin-right: 9%;
  width: 80vw;
}

.about-shah-desktop .secpage {
  align-items: center;
}

.about-shah-desktop .secpage .long2 {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.15em;
  margin-top: 30px;
  margin-bottom: 45px;
}

.about-shah-desktop .secpage .ul-1 .li-1 {
  color: black;
  font-family: "Montserrat", Sans-serif;
  color: orangered;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  margin-top: 20px;
}

.about-shah-desktop .secpage .ul-1 {
  margin-top: -10px;
}

.about-shah-desktop .secpage .ul-1 p {
  color: #000;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  line-height: 1.8;
}

.about-shah-desktop .secpage .ul-1 .ul-2 {
  color: #000;
  font-family: "Jost", Sans-serif;
  line-height: 1.5;
  font-weight: lighter !important;
  margin-top: 20px;
  margin-left: -8px;
}

.about-shah-desktop .secpage .ul-1 .ul-2 .li-2 {
  color: #000;
  cursor: pointer;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  line-height: 1.8;
  list-style: none;
  font-weight: 400;
  margin-left: -27px;
}

.about-shah-desktop .secpage .ul-1 .ul-2 .li-2 a {
  text-decoration: none;
  color: black;
}

.about-shah-desktop .secpage .ul-1 .ul-2 .li-2 a:hover {
  color: #ff5722;
}

.about-shah-desktop .secpage .lastblock {
  height: 100px;
  margin-left: -470px;
}

.about-shah-desktop .secpage .lastblock .pa1 {
  color: #000;
  margin-top: 13px;
}

.about-shah-desktop .secpage .lastblock .pa1 b {
  color: #000;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.about-shah-desktop .secpage .lastblock .pa1 a {
  color: #ff5722;
  font-family: "Jost", Sans-serif;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  opacity: 1;
}

.about-shah-desktop .inception {
  margin-top: 250px;
}

.about-shah-desktop .inception .line1 {
  height: 1.2px;
  margin-top: -1px;
  background-color: rgba(44, 43, 43, 0.123);
  width: 80vw;
}

.about-shah-desktop .inception .dark1 {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 1.5em;
  text-align: center;
  font-weight: 600;
  line-height: 1.15em;
  margin-top: -225px;
  /* margin-left: -245px; */
}

.about-shah-desktop .inception .dark {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.15em;
  text-align: center;
}

#message {
  font-family: "Jost", sans-serif;
  padding: 0 1em;
  text-align: justify;
}

.about-shah-desktop .inception .allpara {
  font-family: "Jost", sans-serif;
}

.about-shah-desktop .inception .allpara .p1 {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  text-align: justify;
}

.about-shah-desktop .inception .allpara ul li {
  padding: 0.5em;
  font-family: "Jost", sans-serif;
  text-align: justify;
}

.about-shah-desktop .inception .message {
  margin-top: 3em;
  font-size: 16px;
  font-family: "Jost", sans-serif;
}

.about-shah-desktop .inception .banner-shah {
  width: 100%;
  height: 325px;
  overflow: hidden;
}

.about-shah-desktop .inception .banner-shah img {
  width: 100%;
  height: auto;
}

.about-shah-desktop .inception .both {
  height: 60px;
  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 60px;
}

.about-shah-desktop .inception .both .share {
  font-size: 16px;
  margin-left: 50px;
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.about-shah-desktop .inception .both .social {
  font-size: 16px;
}

.about-shah-desktop .inception .both .social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
}

.about-shah-desktop .inception .both .social ul li {
  list-style: none;
  text-align: center;
  text-decoration: none;
  border-radius: 50% 50% 50% 50%;
  width: 40px;
  height: 40px;
  line-height: 38px;
  color: #ffffff;
  background-color: #ff5722;
  margin-right: 30px;
}

.about-shah-desktop .over {
  height: 433px;
  background-color: #abbcf1;
  opacity: 0.1;
  position: relative;
}

.about-shah-desktop .con {
  height: 200px;
  width: 1200px;
  position: absolute;
  opacity: 1 !important;
  margin-top: -310px;
  margin-left: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about-shah-desktop .con .firsty {
  height: 200px;
  width: 250px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #8f99cd3b;
  border-radius: 30px 0px 30px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  margin-left: 40px;
}

.about-shah-desktop .con .firsty .ioco {
  height: 40px;
  width: 30px;
  margin-top: 40px;
  background-color: #ff5722;
}

.about-shah-desktop .con .firsty .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.about-shah-desktop .con .firsty .content .number-percentage-wraper {
  color: #011633;
  font-family: "Montserrat", Sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.about-shah-desktop .con .firsty .content .number-percentage-wraper .number-percentage {
  margin-right: 10px;
}

.about-shah-desktop .con .firsty .content .title {
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-top: 6px;
}

.about-shah-desktop .lastsec img {
  position: relative;
  height: 1000px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.07;
}

.about-shah-desktop .lastsec .find {
  position: absolute;
  height: 640px;
  width: 480px;
  margin-top: -750px;
  margin-left: 130px;
}

.about-shah-desktop .lastsec .find h2 {
  color: #011633;
  font-family: "Montserrat", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.15em;
}

.about-shah-desktop .lastsec .find p {
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.about-shah-desktop .lastsec .find ul li {
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  line-height: 38px;
}

.about-shah-desktop .lastsec .find .video-content {
  height: 80px;
  width: 300px;
  margin-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.about-shah-desktop .lastsec .find .video-content .play {
  height: 60px;
  width: 60px;
  background-color: #ff5722;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 50%;
  color: white;
}

.about-shah-desktop .lastsec .find .video-content .play i {
  margin-top: 21px;
  margin-left: 25px;
  font-size: 20px;
}

.about-shah-desktop .lastsec .find .video-content .download {
  height: 20px;
  width: 20px;
  background-color: yellow;
  margin-left: 20px;
  margin-top: 30px;
}

.about-shah-desktop .lastsec .find .video-content .linkk {
  margin-left: 20px;
  margin-top: 30px;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.about-shah-desktop .lastsec .find .video-content .linkk:hover {
  color: #ff5722;
}

.about-shah-desktop .lastsec .aww {
  height: 200px;
  width: 200px;
  background-color: #ff5722;
  position: absolute;
  z-index: 1;
  margin-top: -400px;
  margin-left: 600px;
  border-radius: 30px 0px 30px 0px;
}

.about-shah-desktop .lastsec .aww .winner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-shah-desktop .lastsec .aww .winner .ii {
  font-size: 48px;
  margin-bottom: 20px;
  color: #ffffff;
  margin-top: 30px;
}

.about-shah-desktop .lastsec .aww .winner .win {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -5px;
}

.about-shah-desktop .lastsec .aww .winner .win .no {
  color: #ffffff;
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.about-shah-desktop .lastsec .aww .winner .win .no span {
  color: #ffffff;
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.about-shah-desktop .lastsec .aww .winner .win h3 {
  color: #dbdbdb;
  font-family: "Jost", Sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-top: -5px;
}

.about-shah-desktop .lastsec .f-cover img {
  position: absolute;
  height: 640.34px;
  width: 541.06px;
  opacity: 1;
  margin-top: -770px;
  margin-left: 700px;
  border-radius: 60px 0px 60px 0px;
}

.about-shah-desktop .effect {
  background-image: url("../img/paral.jpg");
  width: 100vw;
  height: 520px;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.about-shah-desktop .say {
  width: 100vw;
  height: 170px;
  position: absolute;
  z-index: 1;
  margin-top: -450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-shah-desktop .say h2 {
  color: white;
  font-family: "Montserrat", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.15em;
  margin-top: -2px;
}

.about-shah-desktop .say p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  color: white;
  margin-top: -1px;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 100;
}

.about-shah-desktop .cover-over {
  width: 100vw;
  height: 520px;
  background-color: #011633;
  position: absolute;
  margin-top: -520px;
  opacity: 0.7;
}

.about-shah-desktop .slider {
  width: 100vw;
  height: 360px;
  position: absolute;
  margin-top: -260px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 50px;
}

.about-shah-desktop .slider .boxy {
  width: 360px;
  height: 360px;
  background-color: white;
  margin-left: 40px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
  -webkit-box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
  -moz-box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
}

.about-shah-desktop .slider .boxy .cardy {
  margin-top: 40px;
}

.about-shah-desktop .slider .boxy .cardy .picy img {
  height: 85px;
  width: 85px;
  border-radius: 50%;
}

.about-shah-desktop .slider .boxy .cardy .profile-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.about-shah-desktop .slider .boxy .cardy .profile-info .author-name {
  margin-top: 20px;
  color: #011633;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.about-shah-desktop .slider .boxy .cardy .profile-info .author-des {
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  font-weight: 300;
  color: #646a7a;
  margin-top: -10px;
}

.about-shah-desktop .slider .boxy .cardy .commentor-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.about-shah-desktop .slider .boxy .cardy .commentor-content ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 80px;
}

.about-shah-desktop .slider .boxy .cardy .commentor-content ul li {
  color: #ff5722;
  font-weight: 900;
  line-height: 1;
  font-size: 20px;
  margin-right: 8px;
}

.about-shah-desktop .slider .boxy .cardy .commentor-content p {
  font-size: 14px;
  font-family: "jost", sans-serif;
  font-weight: 300;
  line-height: 1.6em;
  color: black;
}

.about-shah-desktop .news {
  width: 100vw;
  height: 300px;
  background-color: white;
}

.about-shah-desktop .real-news {
  width: 80vw;
  height: 175px;
  background-color: #ffffff;
  position: absolute;
  margin-top: -60px;
  margin-left: 140px;
  border-radius: 5px;
  box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
  -webkit-box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
  -moz-box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
}

.about-shah-desktop .lasty {
  width: 100vw;
  height: 700px;
  background-color: #011633;
}

.about-shah-mobile {
  overflow: hidden;
  height: auto;
}

.about-shah-mobile .om {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.15em;
  text-align: center;
  margin-top: 12%;
}

.about-shah-mobile .long {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.15em;
  margin-left: 23px;
  margin-right: 23px;
  text-align: center;
}

.about-shah-mobile p {
  color: #000;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  line-height: 1.5;
  opacity: 1;
  margin-left: 23px;
  margin-right: 23px;
  text-align: justify;
  margin-bottom: 23px;
  font-weight: 400;
}

.about-shah-mobile .disclaimer-section .header {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4em;
  font-weight: 600;
  text-align: center;
  color: orangered;
  padding-top: 1em;
}

.about-shah-mobile .disclaimer-section .para {
  font-family: "Jost", sans-serif;
}

.about-shah-mobile ul li {
  padding: 0.5em;
  margin-top: -1em;
}

.about-shah-mobile .p1 {
  float: left;
  margin-left: -480px;
}

.about-shah-mobile .line1 {
  height: 1.2px;
  margin-top: 40px;
  background-color: rgba(44, 43, 43, 0.123);
  margin-left: 9%;
  margin-right: 9%;
  width: 80vw;
}

.secpage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.secpage .long2 {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.15em;
  margin-bottom: -0.5em;
}

.secpage .ul-1 .li-1 {
  color: orangered;
  font-family: "Montserrat", Sans-serif;
  font-size: 1.2em;
  line-height: 1.5;
  font-weight: 500;
  margin-top: 1em;
}

.secpage .ul-1 p {
  color: #000;
  font-weight: 400;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  line-height: 1.8;
}

.secpage .ul-1 .ul-2 {
  color: #000;
  font-family: "Jost", Sans-serif;
  line-height: 1.5;
  margin-left: -33px;
}

.secpage .ul-1 .ul-2 .li-2 img {
  height: 10px;
  margin: 0 10px 0 0;
  width: 15px;
}

.secpage .ul-1 .ul-2 .li-2 {
  color: #000;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  line-height: 1.8;
  list-style: none;
}

.secpage .ul-1 .ul-2 .li-2 a {
  text-decoration: none;
  color: black;
}

.secpage .ul-1 .ul-2 .li-2 a:hover {
  color: #ff5722;
}

.secpage .lastblock {
  height: 100px;
}

.secpage .lastblock .pa1 {
  color: #000;
  text-align: left;
  margin-left: 32px;
}

.secpage .lastblock .pa1 b {
  color: #011633;
  font-family: "Jost", Sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.secpage .lastblock .pa1 a {
  color: #ff5722;
  font-family: "Jost", Sans-serif;
  margin-left: 8px;
  font-size: 14px;
  cursor: pointer;
}

.secpage .line1 {
  height: 1.2px;
  margin-top: -1px;
  background-color: rgba(44, 43, 43, 0.123);
  width: 80vw;
}

.inception-mobile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inception-mobile .dark {
  color: #ff5722;
  font-family: "Montserrat", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.15em;
  margin-bottom: 1em;
}

.inception-mobile .banner-shah {
  height: auto;
  width: 100%;
  overflow: hidden;
}

.inception-mobile .banner-shah img {
  height: auto;
  width: 100%;
}

.inception-mobile .allpara {
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}

.inception-mobile .both {
  height: 60px;
  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 60px;
}

.inception-mobile .both .share {
  font-size: 16px;
  margin-left: 50px;
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.inception-mobile .both .social {
  font-size: 16px;
}

.inception-mobile .both .social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
}

.inception-mobile .both .social ul li {
  list-style: none;
  text-align: center;
  text-decoration: none;
  border-radius: 50% 50% 50% 50%;
  width: 40px;
  height: 40px;
  line-height: 38px;
  color: #ffffff;
  background-color: #ff5722;
  margin-right: 30px;
}

.over {
  height: 433px;
  background-color: #abbcf1;
  opacity: 0.1;
  position: relative;
}

.con {
  height: 200px;
  width: 1200px;
  position: absolute;
  opacity: 1 !important;
  margin-top: -310px;
  margin-left: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.con .firsty {
  height: 200px;
  width: 250px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #8f99cd3b;
  border-radius: 30px 0px 30px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  margin-left: 40px;
}

.con .firsty .ioco {
  height: 40px;
  width: 30px;
  margin-top: 40px;
  background-color: #ff5722;
}

.con .firsty .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.con .firsty .content .number-percentage-wraper {
  color: #011633;
  font-family: "Montserrat", Sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.con .firsty .content .number-percentage-wraper .number-percentage {
  margin-right: 10px;
}

.con .firsty .content .title {
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-top: 6px;
}

.lastsec img {
  position: relative;
  height: 1000px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.07;
}

.lastsec .find {
  position: absolute;
  height: 640px;
  width: 480px;
  margin-top: -750px;
  margin-left: 130px;
}

.lastsec .find h2 {
  color: #011633;
  font-family: "Montserrat", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.15em;
}

.lastsec .find p {
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.lastsec .find ul li {
  color: black;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  line-height: 38px;
}

.lastsec .find .video-content {
  height: 80px;
  width: 300px;
  margin-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.lastsec .find .video-content .play {
  height: 60px;
  width: 60px;
  background-color: #ff5722;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 50%;
  color: white;
}

.lastsec .find .video-content .play i {
  margin-top: 21px;
  margin-left: 25px;
  font-size: 20px;
}

.lastsec .find .video-content .download {
  height: 20px;
  width: 20px;
  background-color: yellow;
  margin-left: 20px;
  margin-top: 30px;
}

.lastsec .find .video-content .linkk {
  margin-left: 20px;
  margin-top: 30px;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.lastsec .find .video-content .linkk:hover {
  color: #ff5722;
}

.lastsec .aww {
  height: 200px;
  width: 200px;
  background-color: #ff5722;
  position: absolute;
  z-index: 1;
  margin-top: -400px;
  margin-left: 600px;
  border-radius: 30px 0px 30px 0px;
}

.lastsec .aww .winner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.lastsec .aww .winner .ii {
  font-size: 48px;
  margin-bottom: 20px;
  color: #ffffff;
  margin-top: 30px;
}

.lastsec .aww .winner .win {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -5px;
}

.lastsec .aww .winner .win .no {
  color: #ffffff;
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.lastsec .aww .winner .win .no span {
  color: #ffffff;
  font-family: "IBM Plex Sans", Sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.lastsec .aww .winner .win h3 {
  color: #dbdbdb;
  font-family: "Jost", Sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-top: -5px;
}

.lastsec .f-cover img {
  position: absolute;
  height: 640.34px;
  width: 541.06px;
  opacity: 1;
  margin-top: -770px;
  margin-left: 700px;
  border-radius: 60px 0px 60px 0px;
}

.effect {
  background-image: url("../img/paral.jpg");
  width: 100vw;
  height: 520px;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.say {
  width: 100vw;
  height: 170px;
  position: absolute;
  z-index: 1;
  margin-top: -450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.say h2 {
  color: white;
  font-family: "Montserrat", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.15em;
  margin-top: -2px;
}

.say p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  color: white;
  margin-top: -1px;
  font-family: "Jost", Sans-serif;
  font-size: 16px;
  font-weight: 100;
}

.cover-over {
  width: 100vw;
  height: 520px;
  background-color: #011633;
  position: absolute;
  margin-top: -520px;
  opacity: 0.7;
}

.slider {
  width: 100vw;
  height: 360px;
  position: absolute;
  margin-top: -260px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 50px;
}

.slider .boxy {
  width: 360px;
  height: 360px;
  background-color: white;
  margin-left: 40px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
  -webkit-box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
  -moz-box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
}

.slider .boxy .cardy {
  margin-top: 40px;
}

.slider .boxy .cardy .picy img {
  height: 85px;
  width: 85px;
  border-radius: 50%;
}

.slider .boxy .cardy .profile-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.slider .boxy .cardy .profile-info .author-name {
  margin-top: 20px;
  color: #011633;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.slider .boxy .cardy .profile-info .author-des {
  font-family: "Montserrat", Sans-serif;
  font-size: 17px;
  font-weight: 300;
  color: #646a7a;
  margin-top: -10px;
}

.slider .boxy .cardy .commentor-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.slider .boxy .cardy .commentor-content ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 80px;
}

.slider .boxy .cardy .commentor-content ul li {
  color: #ff5722;
  font-weight: 900;
  line-height: 1;
  font-size: 20px;
  margin-right: 8px;
}

.slider .boxy .cardy .commentor-content p {
  font-size: 14px;
  font-family: "jost", sans-serif;
  font-weight: 300;
  line-height: 1.6em;
  color: black;
}

.news {
  width: 100vw;
  height: 300px;
  background-color: white;
}

.real-news {
  width: 80vw;
  height: 175px;
  background-color: #ffffff;
  position: absolute;
  margin-top: -60px;
  margin-left: 140px;
  border-radius: 5px;
  box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
  -webkit-box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
  -moz-box-shadow: 2px 2px 18px 4px rgba(128, 128, 128, 0.23);
}

.lasty {
  width: 100vw;
  height: 700px;
  background-color: #011633;
}



.whats-app-class {
  color: green;
  font-size: 40px;
  cursor: pointer;
  position: fixed;
  bottom: 5%;
  right: .5em;
  padding: 15px;
  z-index: 99999;

}

@media(min-width:350px)and (max-width:567px) {
  .whats-app-class {


    right: 0.05rem;


  }
}

.RRT__tabs {
  justify-content: center !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
