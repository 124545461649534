@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.signup {
  height: 100vh;
  width: 100vw;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: 'Jost', sans-serif;
  // overflow: auto;
  @media (min-width: 350px) and (max-width: 786px) {
    height: 100%;
    width: 100%;
  }

  .signup-form {
    height: 'auto';
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 992px) {
      width: 300px;
    }

    .logo {
      height: 98px;
      margin-bottom: 15px;
      margin-top: 34px;

      @media only screen and (max-width: 992px) {
        height: 62px;
      }
    }

    .googlebtn {
      .googlename {
        margin-top: -3px;

        @media only screen and (max-width: 992px) {
          margin-top: -1.5px;
        }
      }

      color: #212529;
      background-color: #fff;
      margin-top: 35px;
      cursor: pointer;
      box-shadow: none;
      display: block;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: 'Jost', sans-serif;
      border: 1px solid #efefef;
      padding: 0.375rem 0.875rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      font-weight: bold;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      .mr-2 {
        margin-top: 5px;
        margin-right: 8px;
      }
    }
  }

  .signup_notes {
    text-align: center;
    margin: 0;

    p {
      font-size: 14px;
      @media (min-width: 350px) and (max-width: 567px) {
        padding: 10px 20px;
      }
    }
  }
}
.login {
  height: 100vh;
  width: 100vw;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: 'Jost', sans-serif;
  // overflow: auto;

  .signup-form {
    height: 'auto';
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 992px) {
      width: 300px;
    }

    .logo {
      height: 98px;
      margin-bottom: 15px;
      margin-top: 34px;

      @media only screen and (max-width: 992px) {
        height: 62px;
      }
    }

    .googlebtn {
      .googlename {
        margin-top: -3px;

        @media only screen and (max-width: 992px) {
          margin-top: -1.5px;
        }
      }

      color: #212529;
      background-color: #fff;
      margin-top: 35px;
      cursor: pointer;
      box-shadow: none;
      display: block;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: 'Jost', sans-serif;
      border: 1px solid #efefef;
      padding: 0.375rem 0.875rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      font-weight: bold;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      .mr-2 {
        margin-top: 5px;
        margin-right: 8px;
      }
    }
  }

  .signup_notes {
    text-align: center;
    margin: 0;

    p {
      font-size: 14px;
      @media (min-width: 350px) and (max-width: 567px) {
        padding: 10px 20px;
      }
    }
  }
}

.extra {
  margin-top: 23px;
  display: flex;
  flex-direction: row;
  opacity: 0.6;
  align-items: center;
  font-family: 'Jost', sans-serif;

  .left-line {
    height: 1px;
    width: 95px;
    margin-right: 12px;
    background-color: #36383ab9;
  }

  .right-line {
    height: 1px;
    width: 95px;
    margin-left: 12px;
    background-color: #36383ab9;
  }
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .MuiFormControl-root {
    margin-top: 22px;
    width: 400px;

    @media only screen and (max-width: 992px) {
      width: 234px;
    }
  }

  button[type='submit'] {
    font-family: 'Montserrat', Sans-serif;
    margin-top: 23px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    fill: #fff;
    color: #fff;
    background-color: #ff5722;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #ff5722;
    padding: 12px 30px 12px 30px;
    width: 230px;
  }
}

.extra-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  font-size: 12px;

  .options {
    margin-top: 3px;
  }

  .signup_notes {
    text-align: center;
    margin: 0;

    p {
      font-size: 14px;
      background-color: #ff5722;
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  opacity: 0;
  margin-bottom: 43px;
  font-family: 'Jost', sans-serif;
  font-size: 12px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 13px;
  }
}

@keyframes ldio-r4q1jc8bpcd {
  0% {
    transform: translate(5px, 39px) scale(0);
  }

  25% {
    transform: translate(5px, 39px) scale(0);
  }

  50% {
    transform: translate(5px, 39px) scale(1);
  }

  75% {
    transform: translate(39px, 39px) scale(1);
  }

  100% {
    transform: translate(73px, 39px) scale(1);
  }
}

@keyframes ldio-r4q1jc8bpcd-r {
  0% {
    transform: translate(73px, 39px) scale(1);
  }

  100% {
    transform: translate(73px, 39px) scale(0);
  }
}

@keyframes ldio-r4q1jc8bpcd-c {
  0% {
    background: #ffffff;
  }

  25% {
    background: #ffffff;
  }

  50% {
    background: #ffffff;
  }

  75% {
    background: #ffffff;
  }

  100% {
    background: #ffffff;
  }
}

.ldio-r4q1jc8bpcd div {
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  transform: translate(39px, 39px) scale(1);
  background: #ffffff;
  animation: ldio-r4q1jc8bpcd 1s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-r4q1jc8bpcd div:nth-child(1) {
  background: #ffffff;
  transform: translate(73px, 39px) scale(1);
  animation:
    ldio-r4q1jc8bpcd-r 0.25s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-r4q1jc8bpcd-c 1s infinite step-start;
}

.ldio-r4q1jc8bpcd div:nth-child(2) {
  animation-delay: -0.25s;
  background: #ffffff;
}

.ldio-r4q1jc8bpcd div:nth-child(3) {
  animation-delay: -0.5s;
  background: #ffffff;
}

.ldio-r4q1jc8bpcd div:nth-child(4) {
  animation-delay: -0.75s;
  background: #ffffff;
}

.ldio-r4q1jc8bpcd div:nth-child(5) {
  animation-delay: -1s;
  background: #ffffff;
}

.loadingio-spinner-ellipsis-wqipn74m1df {
  width: 14px;
  height: 14px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-r4q1jc8bpcd {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.14);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-r4q1jc8bpcd div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */
