@import "../../styles/mediaQuery.scss";

.shopingCard-main {
    display: flex;
    width: 350px;
    margin: 1em;

    .frame {
        cursor: pointer;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 3px 3px 14px -5px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 3px 3px 14px -5px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 3px 3px 14px -5px rgba(0, 0, 0, 0.75);

        .image {
            transition: 0.3s;

            .image-container {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                height: 100%;
                width: 100%;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            &:hover {
                transform: translateY(-8px);
            }
        }

        background-color: white;

        .content-section {
            padding: 0 0.5em;

            .sticker {
                margin: 0 2em;
                display: flex;
                justify-content: flex-end;

                .content {
                    font-family: "Jost", sans-serif;
                    padding: 1.1em 1em;
                    color: white;
                    text-align: center;
                    font-weight: 500;
                    line-height: 1.3;
                    font-size: 0.85em;
                    background-color: orangered;
                    border-radius: 50%;
                    transform: translateY(-35px);
                }
            }

            .content {
                padding: 0 1em;
                transform: translateY(-40px);

                .header {
                    margin-top: 0.5em;
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.2em;
                    font-weight: 550;
                }

                .sub-content {
                    font-family: "Jost", sans-serif;
                    margin-top: 1em;
                    text-align: justify;
                }
            }

            .last-section {
                margin-top: 0.4em;
                font-family: "Jost", sans-serif;
                transform: translateY(-25px);
                display: flex;
                justify-content: space-around;

                .lesson {
                    #book {
                        color: orangered;
                        margin-right: 0.3em;
                    }
                }

                .student {
                    #children {
                        color: orangered;
                        margin-right: 0.3em;
                    }
                }

                .review-section {
                    #children {
                        color: orangered;
                        margin-right: 0.3em;
                    }
                }
            }
        }
    }
}