@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.quizaap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #252d4a;
  color: #fff;
  font-family: 'Jost', sans-serif;
  img {
    height: auto;
    width: 423px;
    margin-top: -84px;
    margin-bottom: 79px;
    @media (max-width: 767px) {
      width: 300px;
      margin-top: -60px;
      margin-bottom: 50px;
      
     }
  }
}
.app {
  background-color: #252d4a;
  width: 450px;
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: space-evenly;

  .score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
  }

  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
  }

  .question-count {
    margin-bottom: 20px;
  }

  .question-count span {
    font-size: 28px;
  }

  .question-text {
    margin-bottom: 12px;
  }

  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }

  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;

    font-family: 'Jost', sans-serif;
  }

  button {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
  }

  .correct {
    background-color: #2f922f;
  }

  .incorrect {
    background-color: #ff3333;
  }

  button:hover {
    background-color: #555e7d;
  }

  button:focus {
    outline: none;
  }

  button svg {
    margin-right: 5px;
  }
}
