.mainDiv {
  border: 2px solid orangered;
  padding: 2rem;
  border-radius: 5px;

  h1 {
    text-align: center;
    color: orangered;
    font-family: 'Jost', sans-serif;
    margin-top: 0;

    span {
      border-bottom: 1px solid orangered;
      font-size: 20px;
    }
  }

  h3 {
    text-align: center;

    font-family: 'Jost', sans-serif;
  }

  h2 {
    text-align: center;
    text-transform: capitalize;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    margin: 1rem 0;
  }

  .btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    padding: 0.8rem 1.5rem;
    font-family: 'Jost', sans-serif;
    font-weight: bold;
    font-size: 22px;
    text-transform: capitalize;
    color: #ffffff;
    background-color: #ff5722;
    border-radius: 0px 3px 3px 0px;
    border: none;
    cursor: pointer;
    margin-top: 0.2px;
    border-radius: 5px;
  }

  .question-main-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin: 1rem 0;

    .question-div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 13px;
      width: 70%;

      .question {
        align-self: flex-start;
        font-family: 'Jost', sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
      }

      input {
        width: 100%;
        border: none !important;
        border-bottom: 1px solid black !important;
        font-family: 'Jost', sans-serif;
        font-size: 1.5rem;
        font-weight: 500;

        &:focus {
          box-shadow: none !important;
          outline: none !important;
          border: none !important;
          border-bottom: 1px solid black !important;
        }
      }
    }

    .ok-btn-div {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        padding: 0.3rem 1.5rem;
        font-family: 'Jost', sans-serif;
        font-weight: unset;
        font-size: 24px;
        text-transform: uppercase;
        color: #ffffff;
        background-color: #ff5722;
        border-radius: 0px 3px 3px 0px;
        border: none;
        cursor: pointer;
        margin-top: 0.2px;
      }
    }
  }

  .arrow-div {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 1px;

    i {
      background-color: #ff5722;
      color: #ffffff;
      padding: 0.5rem;
      border-radius: 5px;

      cursor: pointer;
    }
  }
}
