.SplashScreen {
  height: 100vh;
  z-index: 999999;
  width: 100vw;
  // background-color: #011633;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 96px;
  }
  [data-load] {
    width: 5em;
    height: 5em;
    border-radius: calc(5 / 16 * 1rem);
    position: relative;
    cursor: wait;
    overflow: clip;
    contain: content;
  }

  [data-load='loaded'] {
    cursor: auto;
  }

  [data-load]::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
    aspect-ratio: 1 / 1;
    background-color: orangered;
  }

  [data-load='loaded']::before {
    animation: explode 0.2s linear;
  }

  [data-load='loaded']::after {
    display: none;
  }

  [data-load='loading']::after {
    content: '';
    position: absolute;
    left: calc(50% - (0.75em / 2));
    top: calc(50% - (0.75em / 2));
    width: 0.75em;
    height: 0.75em;
    background-color: orangered;
    border-radius: 50%;
    display: block;
    animation: wiggle 0.3s linear infinite alternate;
  }

  @keyframes wiggle {
    0%,
    20% {
      transform: translatex(-1em) scalex(1);
      animation-timing-function: ease-in;
    }

    50% {
      transform: translatex(0) scalex(2);
      animation-timing-function: ease-out;
    }

    80%,
    100% {
      transform: translatex(1em) scalex(1);
    }
  }

  @keyframes explode {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 0.5;
    }

    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
}

@keyframes ldio-uea5g4cud6 {
  0% {
    transform: translate(5.550000000000001px, 43.290000000000006px) scale(0);
  }
  25% {
    transform: translate(5.550000000000001px, 43.290000000000006px) scale(0);
  }
  50% {
    transform: translate(5.550000000000001px, 43.290000000000006px) scale(1);
  }
  75% {
    transform: translate(43.290000000000006px, 43.290000000000006px) scale(1);
  }
  100% {
    transform: translate(81.03px, 43.290000000000006px) scale(1);
  }
}
@keyframes ldio-uea5g4cud6-r {
  0% {
    transform: translate(81.03px, 43.290000000000006px) scale(1);
  }
  100% {
    transform: translate(81.03px, 43.290000000000006px) scale(0);
  }
}
@keyframes ldio-uea5g4cud6-c {
  0% {
    background: #ff5722;
  }
  25% {
    background: #ff5722;
  }
  50% {
    background: #ff5722;
  }
  75% {
    background: #ff5722;
  }
  100% {
    background: #ff5722;
  }
}
.ldio-uea5g4cud6 div {
  position: absolute;
  width: 24.42px;
  height: 24.42px;
  border-radius: 50%;
  transform: translate(43.290000000000006px, 43.290000000000006px) scale(1);
  background: #ff5722;
  animation: ldio-uea5g4cud6 0.819672131147541s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.ldio-uea5g4cud6 div:nth-child(1) {
  background: #ff5722;
  transform: translate(81.03px, 43.290000000000006px) scale(1);
  animation: ldio-uea5g4cud6-r 0.20491803278688525s infinite cubic-bezier(0, 0.5, 0.5, 1),
    ldio-uea5g4cud6-c 0.819672131147541s infinite step-start;
}
.ldio-uea5g4cud6 div:nth-child(2) {
  animation-delay: -0.20491803278688525s;
  background: #ff5722;
}
.ldio-uea5g4cud6 div:nth-child(3) {
  animation-delay: -0.4098360655737705s;
  background: #ff5722;
}
.ldio-uea5g4cud6 div:nth-child(4) {
  animation-delay: -0.6147540983606558s;
  background: #ff5722;
}
.ldio-uea5g4cud6 div:nth-child(5) {
  animation-delay: -0.819672131147541s;
  background: #ff5722;
}
.loadingio-spinner-ellipsis-6vo0bbys1xq {
  width: 111px;
  height: 111px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-uea5g4cud6 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-uea5g4cud6 div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
