@import '../../styles/mediaQuery.scss';
.courseOverview {
  width: 370px;
  position: absolute;
  right: 0;
  margin-right: 3%;
  margin-top: -18em;
  background: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @include responsive(phone) {
    width: 100%;
  }
  .main-box {
    padding: 0.8em;
    box-shadow: 1px 1px 32px -23px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 1px 32px -23px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 1px 32px -23px rgba(0, 0, 0, 0.75);
    .image-video-section {
      position: relative;
      .image-container {
        position: relative;
        overflow: hidden;
        // background-color: black;
        .blur {
          height: 99%;
          width: 100%;
          position: absolute;
          background: #0000008f;
        }
        img {
          height: 100%;
          width: 100%;
          // opacity: 0.5;
        }
      }

      .play-button-main {
        position: absolute;
        top: 37%;
        left: 31%;
        text-align: center;
        @include responsive(phone) {
          top: 40%;
          left: 38%;
        }
        .play-button-container {
          font-size: 20px;
          background-color: orangered;
          display: inline-block;
          padding: 0.9em 1em;
          border-radius: 50%;
          color: white;
          cursor: pointer;
          @include responsive(phone) {
            font-size: 16px;
          }
          .play-button {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .text {
          padding-top: 0.5em;
          font-family: 'Jost', sans-serif;
          color: white;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .option-section {
      margin-top: 1em;
      select {
        font-weight: 600px;
        font-family: 'Jost', sans-serif;
        padding: 0.5em;
        color: orangered;
        font-size: 17px;
        width: 100%;
        outline: none;
        border: 1px solid orangered;
        background: #fff;
      }
    }
    .students-price {
      padding-top: 1em;
      .main-box {
        border: 1px solid orangered;
        .header {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          text-decoration: underline;
        }
        hr {
          background-color: orangered;
        }
        .price {
          font-family: 'Montserrat', sans-serif;
          text-align: center;
          font-size: 28px;
          font-weight: 600;
        }
        .regular-price {
          font-family: 'Jost', sans-serif;
          font-weight: 400;
          text-align: center;
          padding: 0.6em 0;
          strike {
            font-weight: 700;
          }
        }
        .para {
          font-family: 'Jost', sans-serif;
          font-weight: 400;
          text-align: center;
        }
      }
    }
    .buy-this-course-button {
      padding-top: 1em;
      button {
        color: #ff4500;
        border-radius: 4px;
        min-width: 100%;
        height: 40px;
        font-weight: 600;
        font-size: 13.5px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        background-color: #fff;
        border: solid 2px #ff4500;
        gap: 10px;
        cursor: pointer;

        // &:after,
        // &:before {
        //   width: 0%;
        //   height: 0%;
        //   content: '';
        //   position: absolute;
        //   border-radius: 100%;
        //   color: #fff;
        // }

        // &:after {
        //   transition: all 0.5s ease;
        //   background-color: #ff4500;
        //   color: #fff;
        // }

        // &:before {
        //   transition: all 0.5s ease-out;
        //   background: #ff4500;
        //   color: #fff;
        // }
        // span {
        //   color: #ff5722;
        //   z-index: 99999;
        // }

        // &:hover {
        //   &:after,
        //   &:before {
        //     width: 100%;
        //     height: 200px;
        //     border-radius: 4px;
        //   }

        //   &:after {
        //     transition: all 0.7s ease-in;
        //   }

        //   &:before {
        //     transition: all 0.5s ease-in;
        //   }
        //   span {
        //     transition: all 0.1s ease-in;
        //     color: #fff;
        //     z-index: 99999;
        //   }
        // }
      }
    }
    .course-features-section {
      padding-top: 1.5em;
      @include responsive(phone) {
        margin-top: 0.5em;
        padding: 1em;
      }
      .header {
        text-align: center;
        color: #011633;
        font-family: 'Montserrat', Sans-serif;
        font-size: 18px;
        font-weight: 600;
        padding: 2em 0 0.5em;
        line-height: 1.15em;
      }
      .feature {
        display: flex;
        justify-content: space-between;
        padding: 0.8em 0 0.5em;
        .question {
          font-family: 'Jost', sans-serif;
          font-weight: bold;
        }
        .answer {
          font-family: 'Jost', sans-serif;
        }
      }
    }
  }
}

.shah-benefits {
  background-color: #fff;
  border: 2px solid #ff4500 !important;
  color: #000;
  font-family: Jost, sans-serif;
  border-radius: 8px;
  margin-top: 34px;
  h2 {
    color: #011633;
    font-family: 'Montserrat', Sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.15em;
    margin-left: 23px;
  }
  ul {
    margin-left: -4px;
    margin-right: 23px;
    margin-top: 7px;
    list-style: none;
    li {
      margin-top: 0.5em;
    }
  }
  // ul li:before {
  //   content: '✓';
  //   color: orangered;
  //   margin-right: 8px;
  //   font-weight: bold;
  // }
}

.referral-code-main-div {
  margin: 1rem 0px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  padding: 10px;
  .text-div {
    display: grid;
    gap: 5px;
    .text {
      font-size: 0.9rem;
      font-family: 'Jost', sans-serif;
    }
    .share-code {
      font-size: 0.9rem;
      font-weight: 900;
      text-align: center;
      font-family: 'Jost', sans-serif;
      background-color: #ff5722;
      color: #f0f0f0;
      border: none;
      border-radius: 5px;
      justify-self: start;
      cursor: pointer;
      padding: 5px 10px;
      justify-self: center;
      text-transform: uppercase;
    }
  }
}

.select-course-contain {
  font-family: 'Montserrat', Sans-serif;
  font-size: 18px;
  border: none;
  font-weight: 600;
}
