@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import '../styles/mediaQuery.scss';

.coursecard {
  background-color: #f8f8f8;
  position: relative;
  height: 373px;
  width: 330px;
  margin-top: 23px;
  padding: 6px;
  border-radius: 14px;
  overflow: hidden;
  cursor: pointer;
  margin-left: 23px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) ease-in-out;
  transition-duration: 2s;
  transition-delay: 3s;
  -webkit-box-shadow: 2px 6px 21px -5px rgba(191, 185, 191, 1);
  -moz-box-shadow: 2px 6px 21px -5px rgba(191, 185, 191, 1);
  box-shadow: 2px 6px 21px -5px rgba(191, 185, 191, 1);

  @media (min-width: 1200px) and (max-width: 1280px) {
    height: 425px;
    width: 325px;
  }
  @media (min-width: 1050px) and (max-width: 1200px) {
    height: 300px;
    width: 260px;
  }
  @media (min-width: 1024px) and (max-width: 1049px) {
    height: 380px;
    width: 280px;
  }
  @media (min-width: 320px) and (max-width: 1023px) {
    height: 370px;
    width: 270px;
  }

  @media only screen and (min-width: 1649px) {
    height: 466px;
    width: 421px;
  }

  @media only screen and (min-width: 2000px) {
    height: 516px;
    width: 506px;
  }

  .inner-course-card {
    height: 100%;
    width: 100%;

    img {
      // height: 53%;
      // width: 100%;
      // position: absolute;
      // top: 0;

      // margin-left: -6px;
      // z-index: 100;
      height: 214px;
      width: 380px;
      position: absolute;
      top: 15px;
      margin-left: 19px;
      z-index: 100;
      border-radius: 10px;
    }
    .card-line {
      border: 1px solid #6767673d;
      margin-bottom: 1rem;
    }

    .price {
      background-color: #ff450000;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px !important;
      width: 366px !important;
      text-transform: uppercase;
      border-radius: 5px;
      margin-left: 14px;
      overflow: hidden;
      font-family: 'Jost', Sans-serif;
      font-weight: 300;
      color: #676767;
      font-size: 16px !important;
      margin-bottom: 1rem;
      border: 1px solid #6767677a;
      transition: 0.3s;

      // background-color: orangered;
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // justify-content: center;
      // height: 80px;
      // width: 80px;
      // text-transform: uppercase;

      // position: absolute;
      // border-radius: 100px;
      // margin-left: 69%;
      // margin-top: 44%;
      // z-index: 130;
      // overflow: hidden;
      // font-family: 'Jost', Sans-serif;
      // font-weight: 300;
      // color: #fff;
      // font-size: 16px;
      @media only screen and (min-width: 1649px) {
        height: 100px;
        width: 100px;
        font-size: 20px;
      }

      @media only screen and (min-width: 2000px) {
        height: 100px;
        width: 100px;
        font-size: 20px;
      }

      @media (min-width: 1025px) and (max-width: 1280px) {
        margin-left: 74.45%;
        margin-top: 53%;
      }
      @media (min-width: 991px) and (max-width: 1024px) {
        margin-left: 70.5%;
        margin-top: 49%;
      }
      @media (min-width: 913px) and (max-width: 992px) {
        margin-left: 75%;
        margin-top: 46%;
      }
      @media (min-width: 821px) and (max-width: 912px) {
        margin-left: 69%;
        margin-top: 52%;
      }
      @media (min-width: 541px) and (max-width: 820px) {
        margin-left: 69%;
        margin-top: 53%;
      }
      @media (min-width: 450px) and (max-width: 540px) {
        margin-left: 69%;
        margin-top: 53%;
      }

      @media (min-width: 399px) and (max-width: 450px) {
        margin-left: 69%;
        margin-top: 53%;
      }
      @media (min-width: 392px) and (max-width: 398px) {
        margin-left: 69.5%;
        margin-top: 53%;
      }
      @media (min-width: 320px) and (max-width: 391px) {
        margin-left: 70%;
        margin-top: 53%;
      }
      span {
        line-height: 20px;
        margin-left: 1.1em;
        transform: scale(0.9);
        font-weight: bold;
      }
    }
    .price:hover {
      background-color: orangered;
      color: #fff;
    }
  }
  .card-content {
    position: absolute;
    bottom: 0;
    margin-bottom: 0rem;
    margin-left: -3px;
    font-weight: bold;
    background-color: #f8f8f8;
    width: 92%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;

    // position: absolute;
    // bottom: 0;
    // margin-bottom: -60%;
    // margin-left: -3px;
    // font-weight: bold;
    // border-top-left-radius: 23px;
    // background-color: #f8f8f8;
    // height: 100%;
    // width: 100%;
    // z-index: 100;
    // padding: 14px;
    // margin-left: -12px;
    // padding-top: 23px;

    @media (min-width: 1281px) and (max-width: 1325px) {
      margin-bottom: -62%;
      margin-left: -6px;
    }
    @media (min-width: 1025px) and (max-width: 1280px) {
      margin-bottom: -58%;
      margin-left: -6px;
    }
    @media (min-width: 993px) and (max-width: 1024px) {
      margin-bottom: -67%;
      margin-left: -6px;
    }
    @media (min-width: 913px) and (max-width: 992px) {
      margin-bottom: -72%;
      margin-left: -6px;
    }
    @media (min-width: 821px) and (max-width: 912px) {
      margin-bottom: -70%;
      margin-left: -6px;
    }
    @media (min-width: 768px) and (max-width: 820px) {
      margin-bottom: -69%;
      margin-left: -6px;
    }
    @media (min-width: 541px) and (max-width: 767px) {
      margin-bottom: -67%;
      margin-left: -6px;
    }
    @media (min-width: 415px) and (max-width: 540px) {
      margin-bottom: -67%;
      margin-left: -6px;
    }
    @media (min-width: 399px) and (max-width: 414px) {
      margin-bottom: -69%;
      margin-left: -6px;
    }
    @media (min-width: 391px) and (max-width: 398px) {
      margin-bottom: -67%;
      margin-left: -6px;
    }
    @media (min-width: 320px) and (max-width: 390px) {
      margin-left: -6px;
      margin-bottom: -67%;
    }
    .title {
      font-size: 17px;
      font-family: 'Jost', Sans-serif;
      margin-bottom: 1rem !important;

      // width: 253px;
      // font-size: 17px;
      // font-family: 'Jost', Sans-serif;
      // margin-bottom: 12px;
      // margin-top: 8px;
      @media (min-width: 1050px) and (max-width: 1200px) {
        width: 241px;
        font-size: 14px;
      }
      @media (min-width: 100px) and (max-width: 1024px) {
        width: 235px;
        ont-size: 16px;
      }
    }
    .main-title {
      font-size: 14px;
      font-family: 'Jost', Sans-serif;
      margin-bottom: 1rem;
      color: #ff5722;
      font-weight: 500;
      margin-left: 1.5%;

      @media (min-width: 1050px) and (max-width: 1200px) {
        width: 241px;
        font-size: 14px;
      }
      @media (min-width: 100px) and (max-width: 1024px) {
        width: 235px;
        ont-size: 16px;
      }
    }

    .description {
      // position: absolute;
      font-weight: 400;
      font-family: 'Jost', Sans-serif !important;
      margin-bottom: 1rem !important;
      margin-left: 1.5%;

      // position: absolute;
      // margin-left: 0%;
      // font-weight: 400;
      // bottom: 0;
      // margin-bottom: 62%;
      // font-family: 'Jost', Sans-serif !important;
      // padding: 0px 33px 0px 0px;
      @media (min-width: 1281px) and (max-width: 1325px) {
        margin-bottom: 70%;
      }
      @media (min-width: 1201px) and (max-width: 1280px) {
        margin-bottom: 79%;
      }
      @media (min-width: 993px) and (max-width: 1024px) {
        margin-bottom: 74%;
        margin-left: 0%;
      }
      @media (min-width: 821px) and (max-width: 992px) {
        margin-bottom: 73%;
        margin-left: 0%;
      }
      @media (min-width: 768px) and (max-width: 820px) {
        margin-bottom: 72%;
        margin-left: 0%;
      }
      @media (min-width: 541px) and (max-width: 767px) {
        margin-bottom: 69%;
        margin-left: 0%;
      }
      @media (min-width: 415px) and (max-width: 540px) {
        margin-bottom: 70%;
        margin-left: 0%;
      }
      @media (min-width: 399px) and (max-width: 414px) {
        margin-bottom: 71%;
        margin-left: 0%;
      }

      @media (min-width: 391px) and (max-width: 399px) {
        margin-left: 0%;
        margin-bottom: 71%;
      }
      @media (min-width: 320px) and (max-width: 390px) {
        margin-left: 0%;
        margin-bottom: 70%;
      }
    }
  }
  transition: 0.2s;
  &:hover {
    transform: scale(1.02);
  }
}
@include responsive(phone) {
  height: 389px;
  width: 310px;
}

// Mobile View
.mobile-coursecard {
  margin-left: -3px;
}

.mobile-home-about {
  margin-top: 64px;
  height: max-content;
  padding-bottom: 33px;
  ul {
    font-family: 'Jost', Sans-serif;
  }
  h2 {
    text-align: center;
    padding-top: 29px;
    text-align: center;
    color: #011633;
    font-family: 'Montserrat', Sans-serif;
    font-size: 23px;
    font-weight: 600;
    line-height: 1.15em;
  }
  p {
    font-family: 'Jost', Sans-serif;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 0.9rem;
    font-weight: 400;
    margin-left: 12px;
    margin-right: 12px;
  }
}

// .card-container {
//   display: flex;
//   flex-wrap: wrap;
// }

.coursecard-1 {
  min-width: 280px;
  max-width: 400px;
  padding: 0.8rem;
  flex: 1;
  margin: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;

  display: flex; /* Use Flexbox to control layout */
  flex-direction: column; /* Stack contents vertically */
  // justify-content: space-between;
  align-items: stretch;
}
.title-card {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff5722;
}
.main-title-card {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  margin: 1rem 0;
}
.border-div {
  border-bottom: 1px solid rgba(103, 103, 103, 0.2);
}
.duration-time-div {
  margin: 1rem 0;
}
.duration-time {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.read-more-div {
  border: 1px solid rgba(103, 103, 103, 0.2);
  color: black;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
  transition: 0.5s;
  font-weight: 700;
  width: 100%;
}
.read-more-div:hover {
  left: 100%;
  transition: ease-out;
  background-color: #ff5722;
  color: #f0f0f0;
}

.coursecard-1 img {
  max-width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Rest of your card styles */

.coursecard-1 .card-content-1 {
  flex: 1; /* Let this part of the card expand */
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.coursecard-1 .description-1 {
  margin-top: auto; /* Push this to the bottom */
  font-size: 12px;
}

.coursecard-1 .price-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
}

.btn-slide2 {
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding: 0;
  border-radius: 5px;
  background: #fdfdfd;
  border: 2px solid #efa666;
  /* margin: 10px; */
  transition: 0.5s;
}

.btn-slide2 {
  border: 2px solid #efa666;
}

.btn-slide2:hover {
  background-color: #efa666;
}

.btn-slide2:hover span.circle2 {
  left: 100%;
  margin-left: -45px;
  background-color: #fdfdfd;
  color: #efa666;
}

.btn-slide2:hover span.circle2 {
  color: #efa666;
}

.btn-slide2:hover span.title2 {
  left: 40px;
  opacity: 0;
}

.btn-slide2:hover span.title-hover2 {
  opacity: 1;
  left: 40px;
}
.read-more-buttons {
  border-radius: 4px;
  min-width: 100%;
  height: 40px;
  font-weight: 600;
  font-size: 13.5px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: solid 1px #ff2a2abd;
  cursor: pointer;

  &:after,
  &:before {
    width: 0%;
    height: 0%;
    content: '';
    position: absolute;
    border-radius: 100%;
  }

  &:after {
    transition: all 0.5s ease;
    background-color: white;
  }

  &:before {
    transition: all 0.5s ease-out;
    background: #ff2a2abd;
  }

  span {
    z-index: 1;
    transition: all 0.3s ease-out;
  }

  &.read-more-buttons {
    background: linear-gradient(90deg, #ff2a2ac3, #ff2a2abd);
    color: white;
  }

  &:hover {
    &.read-more-buttons {
      color: #000;
    }

    &:after,
    &:before {
      width: 300px;
      height: 200px;
      border-radius: 4px;
    }

    &:after {
      transition: all 0.7s ease-in;
    }

    &:before {
      transition: all 0.5s ease-in;
    }
  }
}

.btn-slide2 span.circle2 {
  display: block;
  background-color: #efa666;
  color: #fff;
  position: absolute;
  float: left;
  margin: 5px;
  line-height: 42px;
  height: 30px;
  width: 30px;
  top: 0;
  left: 0;
  transition: 0.5s;
  border-radius: 50%;
}

.btn-slide2 span.circle2 {
  background-color: #efa666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  position: absolute;
  left: 90px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: #efa666;
  transition: 0.5s;
}

.btn-slide2 span.title2,
.btn-slide2 span.title-hover2 {
  color: #efa666;
  left: 120px;
}

.btn-slide2 span.title-hover2 {
  left: 80px;
  opacity: 0;
}

.btn-slide2 span.title-hover2 {
  color: #fff;
}

/* Media query for screens up to 600px */
@media (max-width: 600px) {
  .coursecard-1 {
    flex-basis: calc(50% - 20px); /* Two cards in a row */
  }
}
