@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@1&display=swap');
.profile-view {
  overflow-x: hidden;
  .full-setting {
    width: 100vw;
    height: 100vh;
    // background-color: bisque;
    background-color: rgb(253, 253, 253);
    .nav1 {
      height: 60px;
      background: #fff;
      border-bottom: 1px solid #dbe2e8;
      color: #2e3d49;
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
    }
    .side {
      width: 70px;
      height: 100vh;
      background-color: #1f2932;
      padding: 0 10px;
      position: fixed;
      top: 0;
      left: 0;

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style: none;
        margin-left: 0;
        padding: 0;
        text-align: center;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          color: #dbe2e8;
          // line-height: 1;
          font-family:
            Open Sans,
            Helvetica,
            sans-serif;
          font-weight: 400;
          margin: 20px 0;
          cursor: pointer;
          img {
            height: 25px;
            width: 25px;
            // margin-top: ;
            padding-bottom: 8px;
          }
        }
      }
    }

    .both {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 90px;
      box-sizing: border-box;
      height: 100%;
      @media (min-width: 320px) and (max-width: 992px) {
        padding-left: 95px;
      }
      @media (min-width: 375px) and (max-width: 393px) {
        padding-left: 102px;
      }
      @media (min-width: 320px) and (max-width: 375px) {
        padding-left: 102px;
      }
      * {
        box-sizing: border-box;
      }
      .both_inner {
        display: flex;
        height: 90%;
        padding: 1rem;
        @media (min-width: 320px) and (max-width: 992px) {
          display: flex;
          padding: 0.5rem;
          flex-direction: column;
        }
      }
      .in-side {
        height: 100%;
        width: calc(100% - 84%);
        background-color: rgba(31, 41, 50, 0.03);
        @media (min-width: 320px) and (max-width: 992px) {
          min-height: 260px;
          width: 100%;
        }
        h4 {
          font-size: 16px;
          margin: 0;
          padding: 15px;
          text-transform: uppercase;
          color: #2e3d49;
          font-weight: 700;
          text-align: left;
          border-bottom: 1px dashed;
        }

        .list {
          // background-color: red;
          height: 100%;
          .click-btn {
            height: 100%;
            // background: rgb(1, 131, 119);
            display: flex;
            gap: 10px;
            flex-direction: column;
            text-decoration: none !important;
            color: #4f4f4f;
            font-weight: 600;
            // padding: 11px 15px;
            border-radius: 2px;
          }
          // .click-btn:hover {
          //   background: #e76100;
          //   color: white;
          //   cursor: pointer;
          // }
        }
      }
      .in-box {
        width: 100%;
        height: 100%;
        @media (min-width: 320px) and (max-width: 992px) {
          width: 100%;
        }
        // background-color: rgb(27, 17, 71);
        .nxt {
          background-color: #fff;
          width: 100%;
          height: 100%;
          .one-one,
          .one-two {
            // width: 50%;
            @media (min-width: 320px) and (max-width: 992px) {
              width: 100%;
            }
          }
          label {
            font-family: 'Jost', sans-serif;
          }
          .lab {
            font-size: 13px;
            margin-top: 0;
            text-align: left;
            opacity: 1;
          }
          .one-one {
            h4 {
              font-size: 14px;
              color: #2e3d49;
              font-weight: 700;
              padding: 15px;
              margin: 0;
              font-size: 20px;
              line-height: 22px;
              @media (min-width: 320px) and (max-width: 992px) {
                text-align: center;
              }
            }

            .pic-input {
              display: flex;
              padding: 15px;
              @media (min-width: 320px) and (max-width: 992px) {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              .photo {
                height: 100px;
                width: 100px;
                // background-color: black;
                border-radius: 50px;
                img {
                  height: 100px;
                  width: 100px;
                  border-radius: 50px;
                  @media (min-width: 320px) and (max-width: 992px) {
                  }
                }
              }
              .photo:hover {
                // height: 100px;
                // width: 100px;
                background-color: black;
                border-radius: 50px;
                // opacity: 0.9;
                z-index: 1;
                cursor: pointer;
              }
              .full-name {
                display: flex;
                flex-direction: column;
                padding-left: 20px;
                width: calc(100% - 100px);
                @media (min-width: 320px) and (max-width: 992px) {
                  padding-left: 0px;
                  width: 100%;
                }

                input {
                  height: 35px;
                  width: 99%;
                  border: 1px solid #dbe2e8;
                  padding: 0 15px;
                  border-radius: 5px;
                  margin-bottom: 15px;
                }
                button {
                  border-radius: 10px;
                  border-style: none;
                  background-color: #e76100;
                  opacity: 1;
                  color: white;
                  cursor: pointer;
                  margin-bottom: 15px;
                  margin-top: 0;
                  display: block;
                  // margin-left: auto;
                  width: 140px;
                }
                button:hover {
                  background-color: #e76000e9;
                }
                p {
                  font-size: 15px;
                  color: #7e7e7e;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-left: 35px;
                  img {
                    height: 16px;
                    width: 16px;
                    margin-left: 8px;
                    margin-right: 10px;
                  }
                }
              }
            }

            .email {
              // background-color: rgb(243, 74, 74);
              width: 100%;
              padding: 0 15px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              input {
                height: 40px;
                width: 100%;
                border: 1px solid #dbe2e8;
                // margin-left: 40px;
                margin-bottom: 15px;
                padding: 0 15px;
                border-radius: 5px;
              }
              .gender {
                font-size: 16px;
                display: flex;
                width: 99%;
                color: #7e7e7e;
                margin-top: 12px;
                margin-left: -280px;
                .male {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-left: 60px;
                  input[type='radio'] {
                    width: 15px;
                    height: 15px;
                    margin-right: 30px;
                    margin-top: 1px;
                  }
                  label {
                    margin-right: 8px;
                  }
                }
              }
              button {
                padding: 10px 10px;
                width: 140px;
                border-radius: 10px;
                border-style: none;
                background-color: #e76100;
                opacity: 0.5;
                color: white;
                margin-left: auto;
                text-transform: uppercase;
                font-family: 'Montserrat', Sans-serif;
                font-weight: 600;
                letter-spacing: 0.5px;
                font-size: 14px;
                cursor: pointer;
              }
              button:hover {
                opacity: 1;
              }
            }
          }
          //    _____________________________________________________
          .one-two {
            // background-color: rgb(19, 139, 55);
            .pass {
              width: 100%;
              // background-color: rgb(19, 95, 139);
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 15px;
              h4 {
                font-size: 14px;
                color: #2e3d49;
                font-weight: 700;
                width: 100%;
                padding-bottom: 15px;
                margin: 0;
                font-size: 20px;
                line-height: 22px;
              }
              input {
                height: 40px;
                width: 100%;
                border: 1px solid #dbe2e8;
                // margin-left: 40px;
                margin-bottom: 15px;
                padding: 0 15px;
                border-radius: 5px;
              }
              button {
                padding: 10px 10px;
                width: 140px;
                border-radius: 10px;
                border-style: none;
                background-color: #e76100;
                opacity: 0.5;
                color: white;
                margin-left: auto;
                margin-top: 0;
                cursor: pointer;
              }
              button:hover {
                opacity: 1;
              }
            }
          }
          //    _________________________
          .one-three {
            width: 55vw;
            height: 500px;
            margin-top: -21px;
            // background-color: rgb(19, 95, 139);
            h4 {
              color: #2e3d49;
              font-weight: 700;
              padding: 15px;
              margin: 0;
              font-size: 20px;
              line-height: 22px;
            }
            .all-doc {
              // background-color: red;

              h5 {
                font-size: 20px;
                color: #7e7e7e;
                margin-left: 40px;
              }

              input {
                margin-left: 40px;
                font-size: 15px;
              }
            }

            button {
              padding: 10px 10px;
              width: 140px;
              border-radius: 10px;
              border-style: none;
              background-color: #e76100;
              opacity: 0.5;
              color: white;
              margin-top: 18px;
              margin-left: 480px;
              cursor: pointer;
            }
            button:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .input-field-row {
      display: flex;
    }

    .input-field {
      width: 50%;
      float: left;
      margin-bottom: 5px;
    }
    .input-field-1 {
      width: 33.33%;
      float: left;
      margin-bottom: 5px;
    }
    .input-field-2 {
      width: 15.33%;
      float: left;
      margin-bottom: 5px;
    }

    .input-field-button {
      display: flex;

      justify-content: space-between;
      margin-top: 23px;
      cursor: 'pointer';
    }
    .personal-note {
      margin-bottom: 0%;
    }

    @media (min-width: 320px) and (max-width: 800px) {
      .personal-note {
        margin-bottom: 1rem;
      }
      .input-field-button {
        display: flex;
        flex-direction: column-reverse;

        cursor: 'pointer';
      }
      .input-field {
        width: 100%;
      }
      .input-field-1 {
        width: 100%;
      }
    }

    @media (min-width: 320px) and (max-width: 800px) {
      .input-field-row {
        display: flex;
        flex-direction: column;
      }
      .input-field-col-4 {
        width: 100%;
      }
    }

    select#Gander-select {
      width: 99% !important;
      border-radius: 5px;
    }

    textarea {
      width: 99% !important;
      border-radius: 5px;
    }

    .edit-button {
      width: 25px;
      height: 21px;
      border-radius: 50%;
      background-color: #e76100;
      border: 1px solid #3333331f;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: -111px;
      color: #fff;
      margin-left: 77px;
    }
    .edit-button:hover {
      background-color: #333;
      color: #fff;
    }

    .purchased-course-card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      // min-width: 280px;
      // max-width: 300px;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      // column-gap: 1rem;
      // row-gap: 1rem;
    }

    .courses {
      display: flex;
      align-items: stretch;
    }
    @media (max-width: 768px) {
      .profile-view .full-setting .both .in-box .nxt .one-one .pic-input .full-name button {
        margin-left: 244px !important;
      }
    }

    @media (max-width: 800px) {
      .input-field.input-field-space.col-6 {
        display: none;
      }
    }
  }

  .form-title {
    margin-top: 5rem !important;
    margin-bottom: 1rem !important;
    display: flex !important;
    justify-content: center !important;
    padding: 3px !important;
  }

  .user-setting-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (min-width: 320px) and (max-width: 567px) {
      display: flex;
    }
  }

  .share-button {
    background-color: #e76100;
    border: 1px solid #ccc;
    border-radius: 11px;
    padding: 0px 0px 0px 0px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-style: none;
    width: 10rem;
    height: 26px;
    margin-top: 13px;
    margin-right: 16px;
    @media (min-width: 320px) and (max-width: 567px) {
      display: none;
    }
  }
  .mobile-button {
    display: none;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center;
    background-color: #e76101;

    justify-content: center;
    align-items: center;
    @media (min-width: 320px) and (max-width: 567px) {
      display: flex;
    }
  }

  .maun-dropdown-button {
    // appearance: none;
    background-color: #e76100;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 0px 8px 0px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-style: none;
  }

  .maun-dropdown-button :hover {
    border-color: #999;
  }

  .maun-dropdown-button :focus {
    outline: none;
    border-style: none;
    border-color: #666;
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.3);
  }

  .maun-dropdown-button ::placeholder {
    color: #999;
  }

  .maun-dropdown-button option {
    color: #333;
    background-color: #fff;
  }
  @media (min-width: 320px) and (max-width: 1440px) {
    .maun-dropdown-button {
      font-size: 12px;
      max-width: none;
      overflow-y: auto;
      -webkit-appearance: menulist;
      -moz-appearance: menulist;
      appearance: menulist;
    }
  }

  .information-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 14px;
  }
  .desktop-select {
    display: block;
  }
  .mobile-select {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 425px) {
    .desktop-select {
      display: none;
    }
    .mobile-select {
      display: block;
    }
  }
  @media (min-width: 320px) and (max-width: 425px) {
    .information-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 14px;
    }
  }

  // Transactions table

  .transactions-container {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: flex-end;
    // padding: 14px;
    overflow-y: auto;
  }

  .table-header {
    display: flex;
    justify-content: center;
    font-size: 25px;
  }

  .rwd-table {
    margin: auto;
    min-width: 80%;
    max-width: 100%;
    border-collapse: collapse;
  }

  .rwd-table tr:first-child {
    border-top: none;
    background: #428bca;
    color: #fff;
  }

  .rwd-table tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f5f9fc;
  }

  .rwd-table tr:nth-child(odd):not(:first-child) {
    background-color: #ebf3f9;
  }

  // .rwd-table th {
  //   display: none;
  // }

  // .rwd-table td {
  //   display: block;
  // }

  .rwd-table td:first-child {
    margin-top: 0.5em;
  }

  .rwd-table td:last-child {
    margin-bottom: 0.5em;
  }

  // .rwd-table td:before {
  //   content: attr(data-th) ': ';
  //   font-weight: bold;
  //   width: 120px;
  //   display: inline-block;
  //   color: #000;
  // }

  .rwd-table th,
  .rwd-table td {
    text-align: left;
  }

  .rwd-table {
    color: #333;
    border-radius: 0.4em;
    overflow: hidden;
  }

  .rwd-table tr {
    border-color: #bfbfbf;
  }

  .rwd-table th,
  .rwd-table td {
    padding: 0.5em 1em;
  }
  @media screen and (max-width: 601px) {
    .rwd-table tr:nth-child(2) {
      border-top: none;
    }
  }
  @media screen and (min-width: 600px) {
    .rwd-table tr:hover:not(:first-child) {
      background-color: #d8e7f3;
    }
    .rwd-table td:before {
      display: none;
    }
    .rwd-table th,
    .rwd-table td {
      display: table-cell;
      padding: 0.25em 0.5em;
    }
    .rwd-table th:first-child,
    .rwd-table td:first-child {
      padding-left: 0;
    }
    .rwd-table th:last-child,
    .rwd-table td:last-child {
      padding-right: 0;
    }
    .rwd-table th,
    .rwd-table td {
      padding: 1em !important;
    }
  }

  .download-button {
    border-radius: 10px;
    background-color: #4caf50;
    color: white;
    padding: 6px 12px;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px 2px;
    cursor: pointer;
  }
}

/* Chat Css */

.chat-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.sidebar-1 {
  flex: 1;
  background-color: #252b40;
  color: #fff;
  padding: 2px;
  min-height: 70vh;
  height: 100%;
  overflow-y: auto;
}

.user-profile img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: #00ab79;
  border-bottom: 1px solid black;
}

.contact-list {
  list-style: none;
  padding: 0;
}

.contact {
  padding: 0.5rem 0 0.5rem 1.1rem;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.chat {
  flex: 3;
  background-color: #f4f4f4;
  position: relative;
  width: 100%;
  height: 100%;
}

.chat-header {
  background-color: black;
  h3 {
    color: white;
    padding: 1rem;
    margin-bottom: 0%;
    margin-top: 0%;
  }
  .back-arrow {
    display: none;
    width: 30px;
    height: 30px;
    background-color: orangered !important;
    color: white;
    z-index: 9999999;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10px;
    @media (max-width: 567px) and (min-width: 350px) {
      display: flex;
    }
  }

  @media (max-width: 567px) and (min-width: 350px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.contact-list-false {
  display: none;
}
.chat-container-false {
  display: none;
}

.chat-header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-messages {
  min-height: 525px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
}

.message {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sent {
  background-color: #1d5c4b;
  align-self: flex-end;
  border-radius: 5px;
}

.sent-p {
  margin-bottom: 0;
  color: #e6ebec;
}

.received {
  border-radius: 5px;
  background-color: #202c32;
  align-self: flex-start;
}

.received-p {
  margin-bottom: 0;
}

.sent-pdf {
  background-color: #1d5c4b;
  align-self: flex-end;
  border-radius: 5px;
  padding: 1rem;
}

.received-pdf {
  padding: 1rem;
  border-radius: 5px;
  background-color: #202c32;
  align-self: flex-start;
}

.message-input {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.message-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.message-input button {
  background-color: #128c7e;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.no-data-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*# sourceMappingURL=volt.css.map */

/* Model.scss */
