.full {
  height: 100vh;
  width: 100vw;
  // background-color: aqua;
  position: relative;
  overflow-x: hidden;
  @media (min-width: 992px) and (max-width: 1280px) {
    height: 100%;
    width: 100%;
  }
  @media (min-width: 820px) and (max-width: 992px) {
    height: 100%;
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 820px) {
    height: 100%;
    width: 100%;
  }

  .course-title {
    font-weight: 500 !important;
  }

  .navbar {
    display: grid;
    grid-template-columns: 1.6fr 3.2fr 0.5fr;
    text-align: center;
    // flex-direction: row;
    // align-items: center;
    // justify-content: space-between;
    background-color: #f9f9f9;
    border-radius: 1px solid #eee;
    .course-item-search {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        padding: 15px;
        // width: 385px;
        width: 400;
        height: 30px;
        height: 30px;
        width: 385px;
        background: transparent;
        float: left;
        font-style: italic;
        color: #aaa;
        font-size: 16px;
        font-weight: 400;
        border: none !important;
      }
      img {
        height: 26px;
        width: 26px;
        position: absolute;
        margin-left: 370px;
        border: none;
      }
    }
    h2 {
      color: #0a0a0a;
      font-size: 16px;
      // line-height: 60px;
      cursor: pointer;
      // font-family: nunito,Arial,sans-serif;
      font-weight: 700;
      margin-left: 25px;
      text-decoration: none;
      font-family: 'Jost', sans-serif;
      @media (min-width: 597px) and (max-width: 820px) {
        line-height: 20px;
        margin-left: 14px;
      }
      @media (min-width: 580px) and (max-width: 597px) {
        line-height: 20px;
        margin-left: 6px;
      }
      @media (min-width: 564px) and (max-width: 580px) {
        line-height: 20px;
        margin-left: 6px;
        font-size: 15px;
      }
      @media (min-width: 550px) and (max-width: 564px) {
        line-height: 20px;
        margin-left: -6px;
        font-size: 15px;
      }
      @media (min-width: 534px) and (max-width: 549px) {
        line-height: 20px;
        margin-left: -11px;
        font-size: 15px;
      }
      @media (min-width: 522px) and (max-width: 533px) {
        line-height: 20px;
        margin-left: -11px;
        font-size: 14px;
      }
    }
    // button {
    //   color: #fff;
    //   // font-size: 16px;
    //   cursor: pointer;
    //   background-color: orangered;
    //   border: 2px solid orangered;
    //   // /* padding: 0 30px; */
    //   height: 36px;
    //   font-family: nunito, Arial, sans-serif;
    //   // font-weight: 400;
    //   // line-height: normal;
    //   // display: inline-block;
    //   // vertical-align: middle;
    //   border-radius: 8px;
    //   //  margin-left: 295px;?
    // }
    img {
      height: 22px;
      width: 22px;
      margin-left: 10px;
      border-left: 2px solid #b8b8b8;
      padding: 10px;
      cursor: pointer;
    }
    @media (min-width: 821px) and (max-width: 1280px) {
      // height: 100%;
      width: 100%;
    }
    @media (min-width: 592px) and (max-width: 820px) {
      height: 100%;
      width: 100%;
    }
    @media (min-width: 320px) and (max-width: 591px) {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .two-part {
    // height: 100%;
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: flex-start;
    .one {
      // height: 100%;
      // width: 25%;
      // overflow-y: scroll;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // border-radius: 1px solid #eee;
      height: 113vh;
      width: 30%;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      .course {
        height: 604px;
        // width: 392px;
        // background-color: rgb(75, 253, 253);
        // margin-left: 30px;
        // margin-top: 100px;
        @media (min-width: 992px) and (max-width: 1280px) {
          height: 100%;
          width: 100%;
        }
        @media (min-width: 820px) and (max-width: 992px) {
          height: 100%;
          width: 100%;
        }
        @media (min-width: 320px) and (max-width: 820px) {
          height: 100%;
          width: 100%;
        }

        h3 {
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.3;
          color: #0a0a0a;
          font-family: 'Jost', sans-serif;
        }
        .topics {
          h3 {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
            color: #000;
            margin-left: 6px;
            margin-bottom: 23px;
            font-family: 'Jost', sans-serif;

            .fa {
              margin-top: 4px;
              font-size: 12px;
              right: 0;
              margin-right: 0%;
            }
          }
          .click {
            // margin-top: 20px;
            // border: 0;
            // cursor: pointer;
            // padding: 25px 50px;
            background-color: #f9f9f9;
            // border-radius: 8px;
            // padding: 17px 0;

            display: flex;
            padding: 15px 0;
            font-size: 16px;
            border-color: #ddd;
            // border-bottom: 1px solid rgb(121, 121, 121);
            // border-top: 1px solid rgb(121, 121, 121);
            // background: #FFF;
            color: #7e7e7e;
            line-height: 1.5;
            font-weight: 400;
            padding-left: 20px;
            // margin-right: 20px;
            img {
              height: 20px;
              width: 20px;
              margin-right: 15px;
              margin-left: 20px;
            }
          }

          ul {
            // margin-top: -1px;
            list-style: none;
            // background-color: chartreuse;

            width: 100%;
            padding-left: 0;
            // margin-left: -2em;
            :hover {
              color: orangered;
            }

            li {
              display: flex;
              padding: 14px 24px;
              font-size: 16px;
              border-color: #ddd;
              border-bottom: 1px solid #eee;
              border-top: 1px solid #eee;
              background: #fff;
              color: #7e7e7e;
              line-height: 1.5;
              font-weight: 400;
              font-family: 'Jost', sans-serif;
              // width: 85%;

              img {
                height: 20px;
                width: 20px;
                margin-right: 8px;
                margin-left: 28px;
                margin-top: -14px;
              }
              span {
                img {
                  height: 20px;
                  width: 20px;
                  margin-right: 15px;
                  opacity: 0.7;
                  position: absolute;
                  right: 0;
                  left: 20px;
                  margin-right: 90%;
                }
              }

              i {
                img {
                  height: 24px;
                  width: 24px;
                  margin-right: 15px;
                  margin-left: 800px;
                  position: absolute;
                }
              }
            }
            li:hover {
              color: orangered;
              cursor: pointer;
            }
            .quiz {
              display: flex;
              justify-content: flex-end;
              .que {
                border-radius: 10px;
                border: 1px solid black;
                color: rgb(32, 31, 31);
                font-size: 14px;
                font-weight: 400;
                margin-left: 60px;
                padding: 4px;
              }
              .min {
                margin-left: 20px;
              }
            }
          }
        }
      }
      @media (min-width: 1250px) and (max-width: 1280px) {
        height: 100%;
        width: 100%;
      }
      @media (min-width: 1025px) and (max-width: 1249px) {
        height: 100%;
        width: 100%;
      }
      @media (min-width: 820px) and (max-width: 1024px) {
        height: 100%;
        width: 100%;
      }
      @media (min-width: 320px) and (max-width: 820px) {
        height: 100%;
        width: 100%;
      }
    }
    .two {
      width: 60%;
      margin-left: 5%;
      // position: absolute;
      // margin-top: -600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-x: hidden;

      @media (min-width: 1025px) and (max-width: 1280px) {
        height: 100%;
        width: 100%;
        margin: 0px;
        position: unset;
        overflow-x: unset;
      }
      @media (min-width: 820px) and (max-width: 1024px) {
        height: 100%;
        width: 100%;
        margin: 0px;
        position: unset;
        overflow-x: unset;
      }
      @media (min-width: 320px) and (max-width: 820px) {
        height: 100%;
        width: 100%;
        margin: 0px;
        position: unset;
        overflow-x: unset;
      }

      .ppp {
        padding: 25px;
        background-color: #ffe0e0;
        width: 840px;
        font-weight: 400;
        color: #0a0a0a;
        font-family: nunito, Arial, sans-serif;
        border-radius: 8px;
        margin-top: 70px;
      }

      .small-text {
        display: flex;
        flex-direction: column;
        align-items: right;
        font-weight: 400;
        font-family: nunito, Arial, sans-serif;
        color: #999999;
        margin-left: 740px;
        // margin-top: 10px;
        span {
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 2px;
          color: #2441e7;
          margin-left: 80px;
          margin-bottom: 10px;
        }
      }

      .form {
        height: 815px;
        width: 980px;
        // background-color: rgb(107, 11, 0);
        margin-top: 30px;
        margin-left: 50px;
        // border: 2px solid #eee;
        border-radius: 10px;
        padding-left: 20px;
        h3 {
          color: #0a0a0a;
          margin-bottom: 10px;
          cursor: pointer;
          font-weight: 400;
          font-family:
            open sans,
            Helvetica,
            Arial,
            sans-serif;
          font-size: 20px;
          font-weight: 400;
          // line-height: 1.8;
        }
        .label-input {
          margin-top: 50px;

          label {
            color: #4f4f4f;
            // margin-bottom: 10px;
            margin-top: 20px;
            font-family:
              open sans,
              Helvetica,
              Arial,
              sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.8;
            margin-right: 10px;
          }

          input {
            margin-bottom: 10px;
            margin-top: 10px;
            width: 800px;
            height: 48px;
            color: #6f7074;
            // padding: 10px 20px;
            // height: 48px;
            border: 1px solid #ddd;
            border-radius: 5px;
            // border: 2px solid #eee;
          }
          p {
            .boxx {
              height: 15px;
              width: 15px;
              // background-color: salmon;
              border: 1px solid rgb(172, 172, 172);
              border-radius: 5px;
              margin-right: 10px;
              margin-top: 4px;
            }

            display: flex;
            font-family:
              open sans,
              Helvetica,
              Arial,
              sans-serif;
            font-size: 16px;

            line-height: 1.8;
            color: #4f4f4f;
            margin-bottom: 10px;
          }
          .rating {
            color: #4f4f4f;
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 16px;
          }

          h5 {
            color: #4f4f4f;
            margin-bottom: -1px;
            // margin-top: 20px;
            font-family:
              open sans,
              Helvetica,
              Arial,
              sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.8;
          }
          .ree {
            //  background-color: red;
            height: 100px;
          }
          button {
            padding: 20px;
            font-size: 16px;
            background-color: #2441e7;
            color: white;
            border-radius: 8px;
            margin-top: 140px;
            border: none;
            position: absolute;
            margin-left: -800px;
          }
        }
      }
    }
    @media (min-width: 1022px) and (max-width: 1280px) {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
    }
    @media (min-width: 821px) and (max-width: 1021px) {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
    }
    @media (min-width: 320px) and (max-width: 820px) {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .back-view-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    margin-right: 42px;
    @media (min-width: 320px) and (max-width: 820px) {
      position: unset;
      margin-right: 0;
    }
  }

  .lock {
    position: absolute;
    right: 0;
    margin-right: 73%;
    opacity: 0.7;
  }
  .coursevideo {
    border: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.first-section {
  height: 100%;
  width: 100%;
}

.container-second {
  height: 572px !important;
}
@media (min-width: 320px) and (max-width: 430px) {
  .container-second {
    height: 240px !important;
  }
}

// rating icons css

.star-rating {
  direction: rtl;
  display: inline-block;
  padding: 20px;
  cursor: default;

  input[type='radio'] {
    display: none;
  }

  label {
    color: #bbb;
    font-size: 2rem;
    padding: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  label:hover,
  label:hover ~ label,
  input[type='radio']:checked ~ label {
    color: #f2b600;
  }
}

.notes-download {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-align: center;
  margin-bottom: '20px';
  margin-top: '30px';
}

.review-form {
  input {
    height: 2rem;
    width: 18rem;
    padding: 6px;
    border-radius: 3px;
    border: 1px solid orangered;
    font-family: 'Jost', sans-serif;
  }

  textarea {
    height: 5rem;
    width: 18rem;
    padding: 6px;
    border-radius: 3px;
    border: 1px solid orangered;
    margin-top: 12px;
    resize: initial;
    font-family: 'Jost', sans-serif;
  }

  select {
    height: 2rem;
    width: 19rem;
    padding: 6px;
    border-radius: 3px;
    border: 1px solid orangered;
    font-family: 'Jost', sans-serif;
    margin-top: 1em;
  }
}

img.headphone-img {
  height: 32px;
  // margin-top: 50px;
  // margin-bottom: -42px;
  // margin-left: -61px;
}

.audio-notes {
  font-size: 14px;
  font-weight: 300;
}

.video-notes {
  font-size: 14px;
  font-weight: 600;
  color: #f88d8d;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

// @media (min-width: 435px) and (max-width: 708px) {
//   .video-notes {
//     margin-left: 2%;
//   }
// }
// @media (min-width: 398px) and (max-width: 434px) {
//   .video-notes {
//     margin-left: 5%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 383px) and (max-width: 397px) {
//   .video-notes {
//     margin-left: 3%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 361px) and (max-width: 382px) {
//   .video-notes {
//     margin-left: 8%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 338px) and (max-width: 360px) {
//   .video-notes {
//     margin-left: 6%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 320px) and (max-width: 337px) {
//   .video-notes {
//     margin-left: 3%;
//     font-size: 12px;
//   }
// }

// @media (min-width: 435px) and (max-width: 708px) {
//   .audio-notes {
//     margin-left: 2%;
//   }
// }
// @media (min-width: 398px) and (max-width: 434px) {
//   .audio-notes {
//     margin-left: 5%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 383px) and (max-width: 398px) {
//   .audio-notes {
//     margin-left: 9%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 361px) and (max-width: 382px) {
//   .audio-notes {
//     margin-left: 8%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 445px) and (max-width: 360px) {
//   .audio-notes {
//     margin-left: 12%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 338px) and (max-width: 444px) {
//   .audio-notes {
//     margin-left: 13%;
//     font-size: 12px;
//   }
// }
// @media (min-width: 320px) and (max-width: 337px) {
//   .audio-notes {
//     margin-left: 12%;
//     font-size: 12px;
//   }
// }

.course-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (min-width: 320px) and (max-width: 591px) {
  .course-button {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.next-button {
  height: 36px;
  font-family: nunito, Arial, sans-serif;
  border-radius: 8px;

  padding: 0px 18px;
}
// @media (min-width: 548px) and (max-width: 591px) {
//   .next-button {
//     margin-left: 49vh;
//   }
// }
// @media (min-width: 508px) and (max-width: 547px) {
//   .next-button {
//     margin-left: 46vh;
//   }
// }
// @media (min-width: 489px) and (max-width: 507px) {
//   .next-button {
//     margin-left: 42vh;
//   }
// }
// @media (min-width: 473px) and (max-width: 488px) {
//   .next-button {
//     margin-left: 38vh;
//   }
// }
// @media (min-width: 457px) and (max-width: 472px) {
//   .next-button {
//     margin-left: 38vh;
//   }
// }
// @media (min-width: 423px) and (max-width: 456px) {
//   .next-button {
//     margin-left: 34vh;
//   }
// }
// @media (min-width: 398px) and (max-width: 422px) {
//   .next-button {
//     margin-left: 33vh;
//   }
// }
// @media (min-width: 385px) and (max-width: 397px) {
//   .next-button {
//     margin-left: 28vh;
//   }
// }
// @media (min-width: 369px) and (max-width: 384px) {
//   .next-button {
//     margin-left: 27vh;
//   }
// }
// @media (min-width: 353px) and (max-width: 369px) {
//   .next-button {
//     margin-left: 25vh;
//   }
// }
// @media (min-width: 338px) and (max-width: 352px) {
//   .next-button {
//     margin-left: 24vh;
//   }
// }
// @media (min-width: 320px) and (max-width: 337px) {
//   .next-button {
//     margin-left: 21vh;
//   }
// }

.previous-button {
  height: 36px;
  font-family: nunito, Arial, sans-serif;
  border-radius: 8px;
}
@media (min-width: 548px) and (max-width: 591px) {
  .previous-button {
    margin-left: 1vh;
  }
}
@media (min-width: 508px) and (max-width: 547px) {
  .previous-button {
    margin-left: 1vh;
  }
}
@media (min-width: 489px) and (max-width: 507px) {
  .previous-button {
    margin-left: 1vh;
  }
}
@media (min-width: 398px) and (max-width: 488px) {
  .previous-button {
    margin-left: 1vh;
  }
}
@media (min-width: 385px) and (max-width: 397px) {
  .previous-button {
    margin-left: 1vh;
  }
}
@media (min-width: 320px) and (max-width: 384px) {
  .previous-button {
    margin-left: 1vh;
  }
}

.navbar .navbar-home img {
  height: 35px !important;
  width: 55px;
  margin-left: 10px;
  border-left: 0px solid #b8b8b8;
  cursor: pointer;
}

.course-item-title {
  display: flex;
  justify-content: center;
  // margin-bottom: 30px !important;
  font-size: 23px !important;
}

// .course-item-title {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   margin-bottom: 30px !important;
//   font-size: 20px !important;
//   padding: 8px 8px;
// }

// .progress {
//   width: 50px; /* Adjust the width as needed */
//   height: 50px; /* Adjust the height as needed */
//   position: relative;
//   background-color: #d8d8d8;
//   border-radius: 50%;
// }

// .progress::after {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   background: linear-gradient(to right, #f2b870, #ff9472);
//   opacity: 0;
//   transition: opacity 1s ease-in-out;
// }

// .progress[data-progress]::after {
//   opacity: 1;
//   content: attr(data-progress);
//   display: flex;

//   align-items: center;
//   justify-content: center;
//   color: #fff;
//   font-size: 20px; /* Adjust the font size as needed */
// }

// .progress {
//   width: 200px; /* Set the width of the progress bar */
//   height: 20px; /* Set the height of the progress bar */
//   background-color: #f0f0f0; /* Set the background color of the progress bar */
//   border-radius: 10px; /* Add rounded corners to the progress bar */
//   position: relative; /* Set the position to allow for pseudo-element positioning */

//   /* Set the color of the progress bar */
//   background-image: linear-gradient(to right, #4caf50, #4caf50);
// }

// .progress::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   background-color: #2196f3; /* Set the color of the progress fill */
//   border-radius: 10px; /* Add rounded corners to the progress fill */
//   width: attr(data-progress); /* Get the progress value from the data attribute */
// }

.progress-bar {
  width: 100%;
  background-color: #ccc;
  height: 20px;
  border-radius: 10px;
  position: relative;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
  transition: width 0.3s ease;
}

/* Styles for filled stars (gold color) */
.filled-star {
  color: orangered;
}

/* Styles for empty stars (white color) */
.empty-star {
  color: white;
}

/* Modal Styles */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal.open {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 1px;
  right: 10px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modal-buttons {
  margin-top: 20px;
}

.cancel-button,
.start-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ccc;
}

.start-button {
  background-color: #4caf50;
  color: white;
  border: none;
}

.cancel-button:hover,
.start-button:hover {
  opacity: 0.8;
}
