@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
@import './mediaQuery.scss';

.navbar {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      height: auto;
      margin-top: 23px;
      width: 240px;
    }

    .line {
      height: 1.2px;
      margin-top: 24px;
      background-color: rgba(44, 43, 43, 0.123);
      margin-left: 5%;
      margin-right: 5%;
    }

    .main-bar {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .follow-us {
      display: flex;
      margin-top: 15px;
      margin-right: 7%;
      flex-direction: row;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;

      position: absolute;
      right: 0;

      i {
        cursor: pointer;
        color: #ff5722;
        font-size: 17px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .follow-us-m {
      display: flex;
      margin-top: 15px;
      margin-right: 7%;
      flex-direction: row;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      right: 0;

      @media (min-width: 320px) and (max-width: 567px) {
        margin-right: 3%;
      }

      i {
        cursor: pointer;
        color: #ff5722;
        font-size: 17px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .menu-hamburger {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: background-color 0.5s ease;
      background-color: #ff5722;
      margin-left: 12%;
      margin-top: 12px;
      padding: 8px 8px 8px 8px;
      width: 45px;
      border-radius: 3px;
      border-style: solid;
      border-width: 0px 0px 0px 0px;
      padding-top: 9px;
      padding-bottom: 4px;
      cursor: pointer;

      span {
        background-color: #ffffff;
        height: 1px;
        width: 100%;
        display: block;
        margin-bottom: 4px;
      }
    }
  }
}

.logobtn {
  position: absolute;
  left: 0px;
  padding-left: 33px;
  margin-top: 24px;

  img {
    height: 51px;
    position: absolute;
  }
}

.btn-close {
  position: absolute;
  right: 0px;
  padding-right: 43px;
  margin-top: 34px;
}

.close-hamburger {
  padding: 10px 10px 10px 10px;
  // margin: 8px 12px 12px 12px;
  width: 45px;
  border-radius: 3px;
  border-width: 0px 0px 0px 0px;
  background-color: #ffffff;
  border-style: solid;
  color: #111111;
  cursor: pointer;

  img {
    height: 21px;
  }
}

.pages-list {
  list-style: none;
  margin-top: 29%;
  -webkit-tap-highlight-color: transparent;

  li {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    cursor: pointer;

    a {
      text-decoration: none;
      color: #000;
    }

    a:hover {
      color: orangered;
    }

    ul {
      margin-left: -15%;
      margin-top: 10px;
      background-color: #eaeaea;

      li {
        padding-top: 13px;
        padding-bottom: 13px;

        a {
          cursor: pointer;
        }

        a:hover {
          color: orangered;
        }
      }
    }
  }
}

.authpages {
  margin-left: 13%;
  background-color: #ff5722;
  font-size: 19px;

  margin-top: 12px;
  padding: 8px 8px 8px 8px;
  padding-left: 12px;
  padding-right: 12px;
  width: max-content;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.download-store {
  margin-left: 8%;
  margin-top: 34px;

  img {
    height: 42px;
    padding: 12px;
    opacity: 0.6;
  }
}

.available {
  margin-left: 18%;
  margin-top: 4px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.fa-chevron-down {
  margin-left: 5px;
  opacity: 0.6;
}

///Desktop

.desktop {
  overflow: hidden;

  .nv {
    display: grid;
    grid-template-columns: 4fr 1fr;
    margin-top: 12px;

    @include responsive(sm-lap) {
      grid-template-columns: 4fr 1.5fr;
    }

    @media only screen and (min-width: 1600px) {
      grid-template-columns: 4fr 0.9fr;
    }

    img {
      height: 75px;
      margin-left: 4%;

      @media only screen and (max-width: 1263.33px) {
        height: 70px;
      }
    }

    .signup-btn {
      margin-top: 25px;

      @include responsive(sm-lap) {
        margin-right: 2em;
      }
    }

    .button {
      font-family: 'Montserrat', Sans-serif;
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      fill: #fff;
      color: #fff;
      background-color: #ff5722;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #ff5722;
      padding: 12px 30px 12px 30px;
    }

    .button:hover {
      background-color: #fff;
      border: 1px solid #ff5722;
      color: #ff5722;
    }
  }

  .line {
    height: 1.2px;
    margin-top: 10px;
    background-color: rgba(44, 43, 43, 0.123);
    margin-left: 5%;
    margin-right: 5%;
  }

  .deskNav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // display: flex;
    height: 12px;
    width: 100vw;
    // background-color: yellowgreen;
    box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);
    -webkit-box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);
    -moz-box-shadow: -10px 27px 14px -9px rgba(202, 188, 188, 0.12);

    .navItem {
      display: flex;
      // margin-left: 42px;
      margin-left: 16.5em;
      margin-top: -4.7em;
      position: absolute;

      @include responsive(sm-lap) {
        margin-left: 3.5em;
      }

      @media only screen and (max-width: 1263.33px) {
        margin-left: 14.5em;
      }

      @media only screen and (min-width: 1400px) {
        margin-left: 20.5%;
      }

      @include responsive(big-desktop) {
        margin-left: 32.5em;
      }

      li {
        list-style: none;
        font-size: 16px;
        font-family: 'Jost', sans-serif;
        // font-weight: 500 !important;
        cursor: pointer;
        margin-left: 30px;
        margin-top: 15px;

        @media only screen and (max-width: 1400px) {
          margin-left: 20px;

          .fa-chevron-down {
            margin-left: 0px;
          }
        }

        @media only screen and (max-width: 1199px) {
          margin-left: 14px;
        }

        @include responsive(sm-lap) {
          font-size: 15px;
        }

        &:hover > a i {
          color: orangered;
          transform: rotate(180deg);
        }

        a {
          text-decoration: none;
          color: #000;
        }

        a:hover {
          color: orangered;
        }

        ul {
          padding-left: 33px;
          position: absolute;
          background-color: #ffffff;
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 13px;
          border-radius: 0px 0px 4px 4px;
          width: 225px;
          z-index: 1000;
          display: none;
          margin-left: -10px;
          padding-bottom: 0.6em;

          margin-top: 0px;

          li {
            margin-left: -25px;
            padding: 3px;
            padding-right: 10px;
            margin-top: 5px;

            a:hover {
              color: orangered;
            }
          }
        }
      }

      li:hover {
        ul {
          display: block;
          transition: cubic-bezier(0.075, 0.82, 0.165, 1);

          &:hover {
            display: block;
          }

          li {
            a:hover {
              color: orangered !important;
            }
          }
        }
      }
    }

    .follow-us {
      display: flex;
      letter-spacing: 1.5px;
      margin-top: 18px;
      margin-right: 5.5%;
      flex-direction: row;
      align-items: center;
      font-family: 'Jost', sans-serif;
      font-size: 10px;
      position: absolute;
      right: 0;

      @media only screen and (max-width: 1269px) {
        display: none;
      }

      i {
        cursor: pointer;
        color: #ff5722;
        font-size: 17px;
        padding-left: 13px;
        // padding-right: 5px;
      }
    }
  }

  .combine {
    display: flex;

    .buttons {
      grid-column: 2/3;
      display: flex;
      align-items: center;
      margin-left: 123px;
      margin-top: 23px;

      img {
        height: 69px;
      }
    }

    .headMain {
      height: 600px;
      width: 68vw;
      background-color: #011633;
      padding-top: 34px;
      padding-left: 34px;

      .headings {
        padding-top: 120px;
        padding-left: 58px;

        h2 {
          color: #ffffff;
          font-family: 'Montserrat', Sans-serif;
          font-size: 2em;
          font-weight: 600;
          width: 80%;

          // line-height: 1.1em;
          span {
            color: #ff5722;
          }
        }

        button {
          font-family: 'Montserrat', Sans-serif;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          line-height: 1.5;
          color: #ffffff;
          background-color: #ff5722;
          border-radius: 3px 3px 3px 3px;
          padding: 10px 20px 10px 20px;
          cursor: pointer;
          border: none;
          margin-top: 12px;
        }
      }

      .start {
        margin-top: 30px;
        margin-left: 120px;
        font-family: 'Montserrat', Sans-serif;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        // fill: #FFFFFF;
        color: #ffffff;
        background-color: #ff5722;
        border-radius: 3px 3px 3px 3px;
        padding: 20px 40px 20px 40px;
        // height: 15px;

        width: 200px;
        cursor: pointer;
      }

      .start:hover {
        background-color: #06ff27;
      }

      .threepic {
        // background-color: red;
        width: 100vw;
        height: 200px;
        margin-top: 60px;

        .only {
          width: 45vw;
          height: 100px;
          // background-color: hotpink;
          margin-left: 45px;
          margin-top: 40px;
          position: absolute;
          align-items: center;

          img {
            margin-top: 20px;
            height: 59.58px;
            width: 119.16px;
            margin-left: 75px;
            opacity: 0.6;
            cursor: pointer;
            filter: grayscale(100%);
          }

          img:hover {
            filter: grayscale(0%);
          }
        }
      }
    }

    .photo {
      img {
        position: absolute;
        height: 570.13px;
        width: 590.48;
        margin-left: -150px;
        margin-top: 50px;
        border-radius: 60px 0px 60px 0px;

        @include responsive(big-desktop) {
          height: 747.13px;
          width: 666.48px;
          margin-left: -270px;
          margin-top: 22px;
        }
      }
    }
  }
}

.m-combine {
  display: flex;
  margin-top: 13px;
  height: 650px;
  overflow: hidden;

  .headMain {
    width: 100vw;
    background-color: #011633;

    .headings {
      padding-top: 30px;
      padding-left: 18px;

      h2 {
        color: #ffffff;
        font-family: 'Montserrat', Sans-serif;
        font-size: 39px;
        font-weight: 600;
        // line-height: 1.1em;
      }

      p {
        color: #efefef;
        margin-top: -20px;
        font-weight: 100;
        font-size: 13px;
        text-align: justify;
        color: #fff;
        margin-right: 23px;
      }
    }

    .start {
      margin-top: 30px;
      margin-left: 23px !important;
      font-family: 'Montserrat', Sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      // fill: #FFFFFF;
      color: #ffffff;
      background-color: #ff5722;
      border-radius: 3px 3px 3px 3px;
      padding: 20px 40px 20px 40px;
      // height: 15px;

      width: 120px;
      cursor: pointer;
    }

    .start:hover {
      background-color: #06ff27;
    }

    .threepic {
      // background-color: red;
      width: 100vw;
      height: 200px;
      margin-top: 60px;

      .only {
        width: 45vw;
        height: 100px;
        // background-color: hotpink;
        margin-left: 45px;
        margin-top: 40px;
        position: absolute;
        align-items: center;

        img {
          margin-top: 20px;
          height: 59.58px;
          width: 119.16px;

          margin-left: 75px;
          opacity: 0.6;
          cursor: pointer;
          filter: grayscale(100%);
        }

        img:hover {
          filter: grayscale(0%);
        }
      }
    }
  }

  .photo {
    img {
      height: 673.13px;
      width: 590.48;
      margin-left: -71%;
      margin-top: -120px;
      border-radius: 60px 0px 60px 0px;
      transform: scale(0.56);
      position: absolute;

      @media only screen and (max-width: 375px) {
        margin-left: -75%;
      }

      @media only screen and (max-width: 360px) {
        margin-left: -81%;
      }

      @media only screen and (max-width: 320px) {
        margin-left: -91%;
        transform: scale(0.46);
      }
    }
  }
}

.m-threepic {
  // background-color: red;
  width: 100vw;
  height: 200px;
  margin-top: 100px;

  .only {
    height: 100px;
    // background-color: hotpink;
    width: 323px;
    margin-top: 40px;
    position: absolute;
    margin-left: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media only screen and (max-width: 320px) {
      width: 223px;
      display: none;
    }

    img {
      margin-top: 20px;
      height: 59.58px;
      width: 119.16px;
      transform: scale(0.6);
      margin-left: -5px;
      opacity: 0.6;
      cursor: pointer;
      filter: grayscale(100%);
    }

    img:hover {
      filter: grayscale(0%);
    }
  }
}

////About tutor

.full-page-abouttutor {
  // height: 125vh;
  width: 100vw;
  // background-color: cyan;

  .nav-abouttutor {
    height: 145px;
    width: 100vw;
    background-color: pink;
  }

  .photo-content-box {
    height: 880px;
    width: 100vw;
    // background-color: purple;
    position: relative;

    .photo {
      height: 700px;
      width: 50vw;
      // background-color: greenyellow;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 530.39px;
        border-radius: 60px 0px 60px 0px;
        height: 586.94px;
        margin-top: 100px;
      }
    }

    .organge-box {
      height: 230px;
      width: 240px;
      background-color: #ff5722;
      position: absolute;
      border-radius: 30px 0px 30px 0px;
      margin-top: 420px;
      margin-left: 410px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .badge-icon {
        height: 59px;
        width: 66px;
        // background-color: white;
        background-image: url('../img/like.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-top: 35px;
      }

      .number {
        color: #ffffff;
        font-family: 'IBM Plex Sans', Sans-serif;
        font-size: 35px;
        font-weight: 600;
        margin-bottom: -12px;
        margin-top: 10px;
        margin-bottom: 4px;
      }

      .badge-title {
        color: #fff;
        font-family: 'Jost', Sans-serif;
        font-size: 19px;
        font-weight: 400;
        text-align: center;
        line-height: 1.3;
        margin-left: 6px;
        margin-right: 6px;
      }
    }

    .content {
      height: 700px;
      width: 50vw;
      // background-color: yellow;
      position: absolute;
      margin-left: 683px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .all-text {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: left;

        h2 {
          color: #011633;
          font-family: 'Montserrat', Sans-serif;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.15em;
          margin-left: 10px;
        }

        p {
          color: #000;
          font-family: 'Jost', Sans-serif;
          font-size: 16px;
          line-height: 1.5;
          padding-right: 67px;
          margin-bottom: -6px;
        }

        .showmore {
          color: #ff5722;
          font-family: 'Jost', Sans-serif;
          font-size: 18px;
          font-weight: 500;
          margin-left: 10px;
          cursor: pointer;
        }

        .md {
          color: #6f747e;
          font-family: 'Jost', Sans-serif;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.5;
          margin-top: 20px;
        }
      }

      //
      .both {
        height: 60px;
        width: 400px;
        // background: chartreuse;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;

        .share {
          font-size: 15px;
          margin-left: 50px;
          color: #6f747e;
          font-family: 'Jost', Sans-serif;
          font-size: 15px;
          font-weight: 300;
        }

        .social {
          font-size: 15px;

          ul {
            display: flex;
            margin-left: -30px;

            li {
              list-style: none;
              text-align: center;
              text-decoration: none;
              border-radius: 50% 50% 50% 50%;
              width: 40px;
              height: 40px;
              line-height: 38px;
              color: #ffffff;
              background-color: #ff5722;
              margin-right: 30px;

              a {
                text-decoration: none;
                color: #fff;
              }
            }
          }
        }
      }

      //
    }

    .box {
      height: 180px;
      width: 500px;
      // background-color: red;
      position: absolute;
      margin-top: 700px;
      margin-left: 73px;

      .box-body {
        h3 {
          margin: 10px 0px 10px 0px;
          padding: 0px 0px 0px 0px;
          color: #011633;
          font-family: 'Montserrat', Sans-serif;
          font-weight: 600;
          font-size: 23px;
        }

        #proudChild {
          padding-bottom: 1em;
        }

        p {
          color: #000;
          font-family: 'Jost', Sans-serif;
          font-size: 16px;

          // margin-bottom: 10px;
          line-height: 1.7;
          text-align: justify;
        }
      }
    }
  }
}

/// About Tutor Mobile

.full-page-abouttutor-mobile {
  font-family: 'Jost', sans-serif;
  text-align: justify;
  margin-left: 43px;
  margin-right: 43px;
  margin-top: 34px;
}

.terms {
  display: flex;
  margin-top: 45px;
  flex-direction: column;
  justify-content: center;
  padding-left: 123px;
  padding-right: 123px;

  @media only screen and (max-width: 992px) {
    padding-left: 23px;
    padding-right: 23px;
  }

  font-family: 'Jost', Sans-serif !important;

  p {
    text-align: justify;
  }

  p:nth-child(1) {
    text-align: center;
    font-size: 24px;
  }

  p:nth-child(2) {
    text-align: center;
    margin-top: -13px;
    font-size: 14px;
  }
}

.about-shah-homepage {
  background-color: #ebf3fe;
  overflow: hidden;
  position: relative;
}

.desktop-call-to-action {
  // margin-top: 223px; if coach
  background-color: #ebf3fe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 999px) {
    margin-top: 63px;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2em;

    @include responsive(tab-port) {
      font-size: 24px;
    }
  }

  p {
    width: 60%;
    text-align: center;
    font-family: 'Jost', sans-serif;

    margin-top: 12px;
    margin-bottom: 12px;

    font-size: 15px;

    @media only screen and (max-width: 999px) {
      margin-top: -12px;
    }
  }

  button {
    font-family: 'Montserrat', Sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    fill: #ffffff;
    color: #ffffff;
    background-color: #ff5722;
    border-radius: 3px 3px 3px 3px;
    padding: 20px 40px 20px 40px;
    cursor: pointer;
    border: none;
    margin-top: 12px;

    @include responsive(tab-port) {
      margin-top: 0px;
      padding: 15px 30px 15px 30px;
    }

    &:hover {
      background-color: #b8330b;
    }
  }
}

.faq-content {
  margin-top: 12px;
  padding: 12px;

  ol {
    font-family: 'Jost', Sans-serif;
  }

  p {
    font-family: 'Jost', Sans-serif;
    margin-left: 43px;
    margin-right: 53px;
  }
}

// <<<<<<< HEAD
///whystudy

.whystudy {
  font-family: 'Jost', Sans-serif;
  margin-left: 43px;
  margin-right: 43px;

  p:nth-child(1) {
    text-align: center;
  }
}

.block {
  margin-top: 393px;
}

// =======
// ___________________________________________
.mob-faq {
  p {
    font-family: 'Jost', Sans-serif;
  }
}

// >>>>>>> 296ab1d5a12657cc34f48e640e4fe14ab920b61e

.whatsapp {
  position: fixed;
  z-index: 100000;
  right: 0;
  bottom: 0;
  margin-right: 3em;
  margin-bottom: 2em;
  cursor: pointer;

  img {
    height: 63px;
    width: 63px;
  }
}

.follow-us-desktop {
  height: 38px;
  background-color: #011633;

  .follow-us {
    display: flex;
    letter-spacing: 1.5px;
    margin-top: 8px;
    margin-right: 5.5%;
    flex-direction: row;
    align-items: center;
    font-family: 'Jost', sans-serif;
    font-size: 10px;
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 16px;

    @media only screen and (max-width: 1269px) {
      display: none;
    }

    i {
      cursor: pointer;
      color: #ff5722;
      font-size: 17px;
      padding-left: 13px;
      // padding-right: 5px;
    }
  }
}

.button:hover {
  background-color: #fff;
  border: 1px solid #ff5722;
  color: #ff5722;
}

.attendwebinar {
  position: absolute;
  margin-left: 5.5%;
  margin-top: 7px;

  a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border: 1px solid #fff;
    padding: 2px 6px 4px;
  }
}

.main-bar {
  .attendwebinar {
    margin-left: 100px;
    margin-top: 12px;

    a {
      color: #000;
    }

    @media only screen and (max-width: 480px) {
      margin-left: 0;
      margin-top: -26px;
      right: 0;
      text-align: center;
      left: 0;
    }
  }
}

.web-app {
  position: absolute;
  color: #fff;
  right: 0;
  margin-right: 23%;
  margin-top: 8px;

  a {
    color: #fff;
    margin: 0 10px;
  }
}
