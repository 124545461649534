@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.seefaq {
  font-size: 12px !important;
}

.full-page {
  @media only screen and (min-width: 1500px) {
    margin-left: 7%;
  }
  @media only screen and (min-width: 1800px) {
    margin-left: 12%;
  }
}

.related {
  @media only screen and (min-width: 1500px) {
    margin-left: 5%;
  }
  @media only screen and (min-width: 1800px) {
    margin-left: 9%;
  }
}

.select-category {
  margin-left: 23px;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: rgba(221, 70, 15, 0.986);
    background-image: none;
    font-family: 'Jost', sans-serif;
  }
  select::-ms-expand {
    display: none;
  }
  position: relative;
  display: flex;
  width: 15em;
  height: 3em;
  line-height: 3;
  background: #fff;
  overflow: hidden;
  border-radius: 0.25em;
  font-family: 'Montserrat', sans-serif;
  padding-left: 6px;
  select {
    flex: 1;
    padding: 0 0.5em;
    color: #fff;
    cursor: pointer;
  }
}

.select-category::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: rgba(221, 70, 15, 0.986);
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
  color: #fff;
}
.select-category:hover::after {
  color: #ffff;
}

.tab-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -4px;
  margin-bottom: 23px;
  .tab {
    padding: 10px;
    padding-left: 13px;
    padding-right: 13px;

    background-color: #dfd9d9;
    color: black;
    font-family: 'Jost', sans-serif;
    margin-left: 5px;

    font-size: 15px;

    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  .tab:nth-child(1) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .tab:nth-child(2) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .tab:hover {
    color: #fff;
    background-color: orangered;
  }
  .tab-active {
    color: #fff;
    background-color: orangered;
  }
}

.price-for-indian-students {
  background-color: #fff !important;
  text-align: center;
  font-family: 'Jost', sans-serif;
  b {
    font-size: 30px;
  }

  span {
    text-align: center;
    padding-left: 5px;
  }

  p {
    font-size: 15px;
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 7px;

    line-height: 1.2;
  }
}

.course-preview {
  margin-bottom: 148px;
  border: 1px solid orangered;
}

.course-overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #eee;
}

.extra-box-coursepage {
  font-family: 'Jost', sans-serif;
  background-color: #fff;
  color: #000;
  overflow: hidden;
  padding-bottom: 12px;
  border: 2px solid orangered !important;
  margin-top: 218px !important;
  h2 {
    color: #000 !important;
  }
  ul {
    margin-left: -4px;
    margin-right: 36px;
    margin-top: 7px;
  }
}

.full-page {
  height: auto;
  width: 100vw;
  // background-color: pink;
  position: relative;
  z-index: 0;
  margin-bottom: 20em;
  .forNav {
    height: 70px;
    width: 100vw;
    background-color: cyan;
  }
  .vedio {
    height: 540px;
    width: 960px;
    // background-color: rgb(16, 111, 111);
    margin-top: 70px;
    margin-left: 30px;
    position: absolute;
    img {
      height: 540px;
      width: 960px;
    }
  }
  .first-sidebar {
    height: fit-content;
    width: 300px;
    // border: 2px solid #eee;
    overflow: hidden;
    // background-color: rgb(167, 95, 0);
    position: absolute;
    margin-top: -20em;
    margin-left: 1020px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;

    .course-price {
      margin-top: 30px;
      .p {
        font-family:
          open sans,
          Helvetica,
          Arial,
          sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.8;
        margin-right: 5px;
      }
      .price::before {
        content: '$';
      }
      .price {
        font-size: 30px;
        font-weight: 700;
        margin: 0 8px;
        color: #0a0a0a;
        margin-right: 12px;
      }
      .origin-price::before {
        content: '$';
      }
      .origin-price {
        color: #c1c1c1;
        font-size: 13px;
        font-weight: 400;
        text-decoration: line-through;
      }
    }
    .button-purchase-course {
      margin-top: 43px;
      color: #fff;
      border: 2px solid transparent;
      background-color: orangered;
      // width: 100%;
      text-align: center;
      font-family: 'Montserrat', sans-serif;

      padding: 10px 50px;
      line-height: normal;
      height: 50px;
      cursor: pointer;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 10px;
    }

    .button-purchase-course:hover {
      color: #fff;
      background-color: rgb(199, 56, 4);
    }

    .course-information {
      h3 {
        font-weight: 400;
        color: #413a3a;
        font-size: 15px;
        line-height: 1.8;
        margin-bottom: 20px;
        margin-top: 30px;
        font-family: 'Jost', sans-serif;
      }
      ul {
        list-style: none;
        margin-left: -20px;
        margin-top: 22px;
        li {
          font-family:
            open sans,
            Helvetica,
            Arial,
            sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.8;
          color: #7e7e7e;
          margin-top: 10px;
          display: flex;
          margin-left: 10px;
          i {
            img {
              margin-top: 4px;
              height: 24px;
              width: 24px;
              margin-left: -20px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .para-information {
    height: 500px;
    width: 960px;
    // background-color: rgb(16, 111, 111);
    margin-top: 29px;
    margin-left: 30px;

    .combine {
      h3 {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.3;
        color: #0a0a0a;
      }
      .info {
        width: 95%;
        p {
          font-family:
            open sans,
            Helvetica,
            Arial,
            sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.8;
          color: #7e7e7e;
        }
        .listy {
          ul {
            list-style: none;
            margin-left: -35px;
            li {
              font-family:
                open sans,
                Helvetica,
                Arial,
                sans-serif;
              font-size: 15px;
              font-weight: 400;
              line-height: 1.8;
              color: #7e7e7e;
              margin-top: 20px;
              i {
                img {
                  height: 11px;
                  width: 15px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .sec-sidebar {
    height: fit-content;
    width: 300px;
    border: 2px solid #eee;
    // background-color: rgb(167, 95, 0);
    overflow: hidden;
    margin-top: 40px;
    margin-left: 1020px;
    border-radius: 8px;
    padding-bottom: 33px;
    h2 {
      // margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.3;
      color: #0a0a0a;
      margin-left: 18px;
      font-family: 'Jost', sans-serif;
      margin-top: 25px;
    }
    .course-feature-list {
      ul {
        margin-top: 18px;
        font-family:
          open sans,
          Helvetica,
          Arial,
          sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8;
        color: #7e7e7e;
        list-style: none;
        // background-color: chartreuse;
        li {
          color: #0a0a0a;
          padding: 8px 30px;
          margin: 0;
          position: relative;
          border-bottom: 1px solid #eee;
          display: flex;
          margin-left: -49px;
          font-family: 'Jost', sans-serif;
          span {
            margin-left: 190px;
            top: 12px;
            color: #7e7e7e;
            position: absolute;
            font-size: 15px;
          }
        }
        .certi {
          border: none;
          // background-color: chartreuse;
        }
      }
    }
  }

  .course {
    height: auto;
    width: 960px;
    // background-color: rgb(75, 253, 253);
    margin-left: 30px;
    margin-top: -75em;
    h3 {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.3;
      color: #0a0a0a;
    }
  }

  .third-sidebar {
    height: 210px;
    width: 300px;
    border: 2px solid #eee;
    // background-color: rgb(167, 95, 0);
    margin-top: -375px;
    margin-left: 1022px;
    border-radius: 8px;
    h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.3;
      color: #0a0a0a;
      margin-left: 28px;
      // margin-top: 25px;
      padding-top: 15px;
    }
    ul {
      list-style: none;
      display: table;
      margin-left: -10px;
      li {
        margin-bottom: 6px;
        margin-top: 10px;
        font-size: 13px !important;
        padding: 5px 18px;
        border: 0;
        outline: none;
        background-color: #edeff7;
        color: #4f4f4f;
        float: left;
        font-weight: 400;
        line-height: 1.8;
        margin-right: 12px;
        border-radius: 20px;
      }
    }
  }

  .instructor {
    height: 177px;
    width: 960px;
    // background-color: rgb(113, 250, 0);
    margin-top: 170px;
    margin-left: 30px;
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.3;
      color: #0a0a0a;
    }
    .author-reviewcontent {
      height: 167px;
      width: 960px;
      // background-color: rgb(11, 7, 255);
      display: grid;
      -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
      .author {
        height: 96px;
        width: 96px;
        margin-top: 23px;
        margin-left: -4px;
        // background-color: rgb(155, 54, 28);
        img {
          height: 96px;
          width: 96px;
          border-radius: 50px;
        }
      }
      .reviewcontent {
        height: 164px;
        width: 860px;
        // background-color: rgb(164, 196, 21);
        // margin-left: 100px;
        // margin-top: -95px;
        .star-user {
          height: 27px;
          width: 860px;
          // background-color: rgb(196, 21, 144);
          display: flex;
          .stars {
            height: 27px;
            width: 150px;
            margin-left: 40px;
            background-color: white;
            display: flex;
            ul {
              display: flex;
              list-style: none;
              margin-left: -40px;
              margin-top: 4px;
              li {
                // display: flex;
                // flex-direction: row;
                // align-items: center;
                // background-color: red;

                img {
                  height: 18px;
                  width: 18px;
                  margin-left: 4px;
                }
              }
            }
            span {
              font-family:
                open sans,
                Helvetica,
                Arial,
                sans-serif;
              font-size: 15px;
              font-weight: 400;
              line-height: 1.8;
              color: #7e7e7e;
              margin-left: 10px;
            }
          }
          .user {
            height: 27px;
            width: 400px;
            margin-left: 270px;
            // background-color: rgb(18, 160, 255);
            display: flex;
            //
            font-family:
              open sans,
              Helvetica,
              Arial,
              sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.8;
            color: #7e7e7e;
            //
            .nb-reviews-user {
              display: flex;
              margin-left: 20px;

              i {
                img {
                  //   background-color: red;
                  height: 20px;
                  width: 20px;
                  margin-right: 10px;
                  margin-top: 3px;
                }
              }
            }
            .nb-students {
              margin-left: 20px;
              display: flex;
              i {
                img {
                  height: 20px;
                  width: 20px;
                  margin-right: 10px;
                  margin-top: 3px;
                }
              }
            }
            .nb-course {
              margin-left: 20px;
              display: flex;
              i {
                img {
                  height: 20px;
                  width: 20px;
                  margin-right: 10px;
                  margin-top: 3px;
                }
              }
            }
          }
        }

        .author-data {
          // background-color: red;
          margin-top: -43px;
          margin-left: 45px;

          h3 {
            font-size: 18px;
            color: #252525;
            font-weight: 600;
            cursor: pointer;
            font-family: 'Jost', sans-serif;
            line-height: 1.3;
            margin-top: 23px;
          }
          .author-bio {
            font-size: 15px;
            font-style: italic;
            margin-top: -12px !important;
            font-family: 'Jost', sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.8;
            color: #7e7e7e;
          }
        }
      }
    }
  }

  .review-progress {
    height: 271px;
    width: 960px;
    // background-color: rgb(9, 56, 82);
    margin-top: 10px;
    margin-left: 30px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      color: #0a0a0a;
    }
    .detailed-rating {
      height: 225px;
      width: 660px;
      margin-top: 10px;
      // background-color: rgb(24, 174, 255);
      .skill {
        display: flex;
        margin-top: 10px;
        .key {
          color: #4f4f4f;
          width: 15%;
          margin-top: -8px;
          margin-top: 10px;
          font-size: 18px;
        }
        .progress {
          height: 10px;
          background-color: #f5f7fa;
          width: 75%;
          border-radius: 20px;
          position: relative;
          margin-top: 20px;
          .progress-bar {
            width: 66.666666666667%;
            height: 10px;
            font-size: 15px;
            background-color: #d0d23c;
            // position: absolute;
            z-index: 1;
            border-radius: 20px;
          }
        }
        .value {
          font-family:
            open sans,
            Helvetica,
            Arial,
            sans-serif;
          font-size: 15px;
          font-weight: 400;
          // line-height: 1.8;
          color: #7e7e7e;
          margin-left: 10px;
          margin-top: 14px;
        }
      }
    }
    .review-board {
      height: 198px;
      width: 240px;
      // background-color: rgb(58, 82, 9);
      margin-left: 700px;
      margin-top: -210px;
      border-radius: 8px;
      // width: 25%;/

      text-align: center;
      background-color: #edeff7;

      .rating-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .average-value {
          font-size: 50px;
          line-height: 60px;
          // margin-bottom: 8px;
          margin-top: 40px;
          color: #0a0a0a;
          font-family: nunito, Arial, sans-serif;
          font-weight: 400;
        }
        ul {
          list-style: none;
          display: flex;
          margin-left: -40px;
          li {
            img {
              height: 18px;
              width: 18px;
              margin-left: 4px;
            }
          }
        }
        .review-amount {
          font-family:
            open sans,
            Helvetica,
            Arial,
            sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.8;
          color: #4f4f4f;
          margin-top: -10px;
        }
      }
    }
  }

  .comment {
    height: 250px;
    width: 960px;
    // background-color: rgb(107, 11, 0);
    border: 2px solid #eee;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 30px;
    display: flex;
    .re-pic {
      height: 250px;
      width: 150px;
      // background-color: rgb(0, 107, 32);
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 70;
        width: 70;
        border-radius: 50px;
        margin-top: 30px;
      }
    }
    .descrip {
      height: 250px;
      width: 810px;
      margin-top: 20px;

      // background-color: rgb(194, 189, 36);
      h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        margin-bottom: 4px;
        color: #0a0a0a;
      }
      time {
        color: #7e7e7e;
        font-weight: 400;
        font-size: 14px;
      }
      ul {
        margin-top: -42px;
        margin-left: 600px;
        list-style: none;
        display: flex;
        position: absolute;
        li {
          img {
            height: 18px;
            width: 18px;
            margin-left: 4px;
          }
        }
      }
      p {
        color: #4f4f4f;
        font-family:
          open sans,
          Helvetica,
          Arial,
          sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.8;
      }
      .re {
        font-family:
          open sans,
          Helvetica,
          Arial,
          sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.8;
        color: #7e7e7e;
        img {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }

  .form {
    height: 815px;
    width: 980px;
    // background-color: rgb(107, 11, 0);
    margin-top: 30px;
    margin-left: 50px;
    h3 {
      color: #0a0a0a;
      margin-bottom: 10px;
      cursor: pointer;
      font-weight: 400;
      font-family:
        open sans,
        Helvetica,
        Arial,
        sans-serif;
      font-size: 20px;
      font-weight: 400;
      // line-height: 1.8;
    }
    .label-input {
      margin-top: 50px;

      label {
        color: #4f4f4f;
        // margin-bottom: 10px;
        margin-top: 20px;
        font-family:
          open sans,
          Helvetica,
          Arial,
          sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8;
      }

      input {
        margin-bottom: 10px;
        margin-top: 10px;
        width: 960px;
        height: 48px;
        color: #6f7074;
        // padding: 10px 20px;
        // height: 48px;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding-left: 23px;
        font-size: 15px;
        font-family: 'Montserrat', Sans-serif;
        // border: 2px solid #eee;
      }
      p {
        display: flex;
        font-family:
          open sans,
          Helvetica,
          Arial,
          sans-serif;
        font-size: 16px;

        line-height: 1.8;
        color: #4f4f4f;
        margin-bottom: 10px;
        .boxx {
          height: 15px;
          width: 15px;
          // background-color: salmon;
          border: 1px solid rgb(172, 172, 172);
          border-radius: 5px;
          margin-right: 10px;
          margin-top: 4px;
        }
      }
      .rating {
        color: #4f4f4f;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
      }
      ul {
        margin-top: -20px;
        margin-left: 250px;
        list-style: none;
        display: flex;
        position: absolute;
        li {
          img {
            height: 18px;
            width: 18px;
            margin-left: 4px;
          }
        }
      }
      h5 {
        color: #4f4f4f;
        margin-bottom: -1px;
        // margin-top: 20px;
        font-family:
          open sans,
          Helvetica,
          Arial,
          sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.8;
      }
      .ree {
        //  background-color: red;
        height: 100px;
      }
      .submit-btn {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 23px;
        padding-right: 23px;
        font-size: 16px;
        background-color: orangered;
        color: white;
        border-radius: 8px;
        margin-top: 20px;
        border: none;
        font-family: 'Montserrat', Sans-serif;
        cursor: pointer;
      }
    }
  }
}

.content-source {
  overflow: hidden !important;
  display: block;
  margin-bottom: 23px;
}

.overview {
  display: block;
  position: relative;
  z-index: 1000;
  overflow: hidden;
  overflow-y: scroll;
  border: 2px solid #eee;
  padding: 6px;
  border-radius: 3px;
  width: calc(100% - 435px);
  margin-left: 2em;
  height: 330px;
}

.overview::-webkit-scrollbar {
  width: 0.3em;
}

.overview::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #fff;
}

.overview::-webkit-scrollbar-thumb {
  background-color: orangered;
  outline: 1px solid #fff;
}

.videoImage {
  position: relative;
  .play {
    height: 53px;
    width: 53px;
    position: absolute;
    cursor: pointer;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
  }

  p {
    margin-top: 23%;
    position: absolute;
    cursor: pointer;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
  }
  .background {
    opacity: 1;
    // height: 23%;
    width: 100%;
    filter: brightness(42%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
  }
}

.expand-details::selection {
  color: none;
  background: none;
}
/* For Mozilla Firefox */
.expand-details::-moz-selection {
  color: none;
  background: none;
}

.expand-details {
  span::selection {
    color: none;
    background: none;
  }
  /* For Mozilla Firefox */
  span::-moz-selection {
    color: none;
    background: none;
  }
}

// .course-modal{
//     // height: 100%;
//     // width: 123px;
//     z-index: 10000;
// }
.video-overview-course {
  //    height: 100%;
  //    position: absolute;
  background-color: #000;
  // width: 100%;
  // margin-top: -23px;
  // overflow: hidden;

  iframe {
    border: 0;
    // height:260px !important;
    // width:840px !important;
    // max-width:840px !important;
    height: 483px !important;
    // z-index: 100000 !important;
  }
}

.ReactModal__Content {
  padding: 0px !important;
  overflow: hidden !important;
  border: 0 !important;
  height: 315px !important;
  width: 440px !important;
  // padding-top: 2px !important;
  max-width: 100% !important;
  background-color: #fff !important;
  // border: 1px solid black !important;
  z-index: 100000 !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 1px 1px 5px green;
  //   top: 15% !important;
  //   transform: translate(-50%,-50%);
  @media (min-width: 400px) and (max-width: 420px) {
    height: 350px !important;
    width: 400px !important;
  }
  @media (min-width: 320px) and (max-width: 399px) {
    height: 350px !important;
    width: 300px !important;
  }
}
.blur-overview {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.623);
  position: fixed;
  z-index: 100000 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-family: 'Jost', sans-serif;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    margin-bottom: -1%;
  }
  p:hover {
    color: orangered;
  }
  .mpop-up-overview {
    background-color: #000;
    padding: 8px !important;
    overflow: hidden !important;
    border: 0 !important;
    height: 209px !important;
    width: 840px !important;
    // padding-top: 2px !important;
    max-width: 100% !important;
    background-color: #000 !important;

    z-index: 100000 !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    margin-top: 2%;
    border-radius: 4px;
  }
  .pop-up-overview {
    background-color: #000;
    padding: 8px !important;
    overflow: hidden !important;
    border: 0 !important;
    height: 483px !important;
    width: 840px !important;
    // padding-top: 2px !important;
    max-width: 100% !important;
    background-color: #000 !important;

    z-index: 100000 !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    margin-top: 2%;
    border-radius: 4px;
  }
}

.mobile-course-page {
  .first-sidebar {
    margin-top: -24em !important;
  }
  .select-category {
    margin-left: 3.5em;
    margin-top: 2em;
  }

  .button-purchase-course {
    margin-top: 43px;
    color: #fff;
    border: 2px solid transparent;
    background-color: #ff4500;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 50px;
    line-height: normal;
    height: 50px;
    cursor: pointer;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 10px;
  }

  .course-information {
    h3 {
      font-weight: 400;
      color: #413a3a;
      font-size: 15px;
      line-height: 1.8;
      margin-bottom: 20px;
      margin-top: 30px;
      font-family: 'Jost', sans-serif;
    }
  }
  .extra-box-coursepage {
    margin-left: 23px;
    margin-right: 29px;
    padding-left: 17px;
    margin-top: 2em !important;
    border-radius: 12px;
  }

  .sec-sidebar {
    height: fit-content;
    width: 300px;
    border: 2px solid #eee;
    overflow: hidden;
    margin-top: 40px;
    margin-left: 1020px;
    border-radius: 8px;
    padding-bottom: 33px;
    font-family: 'Jost';
    margin-left: 2em;
    padding-left: 1em;
    h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.3;
      color: #0a0a0a;
      margin-left: 18px;
      font-family: 'Jost', sans-serif;
      margin-top: 25px;
    }
    ul {
      margin-top: 18px;
      font-family:
        open sans,
        Helvetica,
        Arial,
        sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8;
      color: #7e7e7e;
      list-style: none;
      li {
        color: #0a0a0a;
        padding: 8px 30px;
        position: relative;
        border-bottom: 1px solid #eee;
        display: flex;
        margin: 0 0 0 -49px;
        font-family: 'Jost', sans-serif;

        span {
          margin-left: 190px;
          top: 12px;
          color: #7e7e7e;
          position: absolute;
          font-size: 15px;
        }
      }
    }

    .course {
      h3 {
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        font-weight: 600;
      }
      .expand-details {
        font-family: Jost, sans-serif;
      }
      .click {
        margin-top: 23px;
      }
    }
  }
}

.download-curriculum {
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 5px 5px 5px 5px;
  border-color: orangered;
  color: orangered;
  padding: 12px;
  font-size: 14px;
  position: absolute;
  right: 0;
  cursor: pointer;
  font-family: 'Montserrat', Sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.5px;
}

.click {
  margin-top: 30px;
  border: 0;
  cursor: pointer;
  padding: 25px 100px 25px 40px;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  display: flex;
  font-family: 'Jost', Sans-serif;
  margin-left: 2em;
  width: calc(100% - 572px);
  border: 2px solid #4f4b4b30;
  .dropdown {
    position: absolute;
    left: 0;
    margin-top: 2px;
    img {
      height: 15px;
      margin-left: 18px;
      cursor: pointer;
      transform: rotate(6deg);
    }
  }
  .dropdowns {
    position: absolute;
    left: 0;
    margin-top: 4px;
    img {
      height: 17px;
      margin-left: 18px;
      cursor: pointer;
      transform: rotate(-90deg);
    }
  }
}

ul {
  .lessonName {
  }
}

.topics {
  height: auto !important;

  ul {
    // .ul{
    // margin-top: -1px;
    // list-style: none;
    // background-color: chartreuse;

    width: 66%;
    // margin-left: -5em;
    // }

    li {
      display: flex;
      padding: 17px 0;
      font-size: 16px;
      border-color: #ddd;
      border-bottom: 1px solid #eee;
      background: #fff;
      color: #7e7e7e;
      line-height: 1.5;
      font-weight: 400;
      position: relative;
      flex-direction: row;

      align-items: center;
      overflow: hidden;
      padding: 17px 85px 17px 25px;

      .lock {
        position: absolute;
        right: 0;
        margin-right: 45px;
        img {
          height: 19px;
          cursor: pointer;
        }
      }
      i {
        img {
          height: 24px;
          width: 24px;
          margin-right: 15px;
          margin-left: 20px;
        }
      }
    }
    li:hover {
      color: orangered;
      cursor: pointer;
    }
    .quiz {
      // background-color: red;
      .que {
        color: white;
        font-size: 14px;
        font-weight: 400;
        margin-left: 33em;
        padding: 4px;
      }
      .min {
        margin-left: 40px;
      }
    }
  }
}
.form-course-comment {
  display: flex;
}
.course-comment {
  margin-top: 2rem;
}
.form-course-comment form {
  display: flex;
  flex-direction: row;
}
.course-comment button {
  font-family: 'Montserrat', Sans-serif;
  margin-top: 0% !important;
  margin-left: 1rem;
  // align-self: flex-start !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  fill: #fff;
  color: #fff;
  background-color: #ff5722;
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #ff5722;
  padding: 12px 30px 12px 30px;
  width: 230px;
}

.course-comment textarea {
  height: 5rem;
  width: 18rem;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid orangered;
  margin-top: 0% !important;
  resize: initial;
  font-family: 'Jost', sans-serif;
}
