@import '..//../styles//mediaQuery.scss';

.footer-grid-container {
  padding-left: 6em;
  padding-right: 6em;
  background-color: #011633;
  padding-top: 7.5em;
  padding-bottom: 0em;

  @include responsive(phone) {
    padding-left: 1em !important;
    padding-right: 1em !important;
    padding-top: 7.5em !important;
  }

  @include responsive(laptop) {
    padding-left: 5em;
    padding-right: 2em;
    padding-top: 7.5em;
    padding-bottom: 0em;
  }

  .top-section {
    width: 100%;
    padding-top: 2em;
    display: flex;
    justify-content: space-evenly;

    @include responsive(phone) {
      width: 100%;
      padding-top: 2em;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    @include responsive(tab-port) {
      width: 100%;
      padding-top: 2em;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    // @include responsive(tab-port) {
    //   width: 100%;
    //   padding-top: 2em;
    //   display: flex;
    //   flex-direction: row;
    //   flex-wrap: wrap;
    //   justify-content: space-evenly;
    // }
    .other-page,
    .quick-links,
    .ImportantCourses,
    .AboutKrutikShah {
      @include responsive(phone) {
        // text-align: center;
      }

      h3 {
        font-family: 'Jost', sans-serif;
        color: white;
        font-size: 1rem;
      }

      ul {
        padding: 0;

        li {
          font-family: 'Jost', sans-serif;
          color: #a8a6a6;
          padding: 0.4em;
          font-weight: 500;
          padding-left: 0;
          list-style-type: none;

          // margin-left: -2.7em;
          &:hover {
            cursor: pointer;
            color: orangered;
          }

          a {
            text-decoration: none;
            color: #a8a6a6;

            &:hover {
              cursor: pointer;
              color: orangered;
            }
          }
        }

        @include responsive(phone) {
          // text-align: center;
          padding-left: 0rem;
        }
      }
    }
  }

  .middle-section {
    // padding-top: 3em;
    padding-left: 8em;

    display: flex;
    margin-top: 2em;

    @media only screen and (min-width: 1400px) {
      padding-left: 7em;
    }

    @media only screen and (min-width: 1650px) {
      padding-left: 15em;
    }

    @include responsive(big-desktop) {
      padding-left: 23em;
    }

    .download {
      .content {
        h2 {
          font-family: 'Montserrat', sans-serif;
          color: orangered;
          font-size: 16px;
          font-weight: 600;
        }

        p {
          font-family: 'Jost', sans-serif;
          color: #fff;
        }
      }
    }

    @include responsive(tab-port) {
      flex-wrap: wrap;
      padding-top: 0em;
      padding-left: 0em;
    }

    .buttons {
      width: 70%;
      display: flex;
      padding-left: 2em;
      align-items: center;

      @include responsive(tab-port) {
        justify-content: center;
        padding-left: 0em;
        padding-top: 1em;
        width: 100%;
      }

      @include responsive(phone) {
        font-size: 0.8em;
        justify-content: center;
        padding-left: 0em;
        padding-top: 0.5em;
        width: 100%;
      }

      .image1 {
        img {
          height: 3em;
        }
      }

      .image2 {
        img {
          height: 4.2em;
        }
      }
    }
  }

  .last-section {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: 0 0 1.5rem 0;

    @include responsive(tab-port) {
    }

    @include responsive(phone) {
    }

    .logo {
      img {
        height: 5em;
      }
    }

    .copyright {
      padding: 1em 0;
      color: #c8c8c8;
      font-family: 'Jost', Sans-serif;

      font-weight: 300;
    }

    .footer-link-special {
      display: flex;
      justify-content: center;
      align-items: center;

      .link-1 {
        margin-right: 0.5em;

        a {
          text-decoration: none;
          color: white;
          font-family: 'Jost', sans-serif;
        }
      }

      .hr-line {
        border-left: 0.1px solid white;
        height: 25%;
      }

      .hr-line::after {
        content: '';
        border-left: 0.1px solid white;
        height: 25%;
      }

      .link-2 {
        margin-left: 0.5em;

        a {
          text-decoration: none;
          color: white;
          font-family: 'Jost', sans-serif;
        }
      }
    }

    hr {
      min-height: 66%;
      max-height: 3vh;
      margin: 0;
      margin-top: 18px;
    }
  }

  .copyright-section {
    padding: 1rem 0em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .copyright {
      color: #c8c8c8;
      font-family: 'Jost', Sans-serif;

      font-weight: 300;
    }

    @include responsive(tab-port) {
      padding: 0em 0em 0em 0em;
      display: flex;
      margin-top: 1em;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
    }

    ul.social-set {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      height: auto;
      width: auto;
      gap: 1rem;
    }

    a.sociali {
      color: #fff;
      font-weight: bolder;
      font-size: 18px;
      padding: 0.25rem;
      background: rgba(black, 0);
      border-radius: 5px;
      border: 1px solid white;
    }

    a.sociali-fa-instagram:hover,
    a.sociali-fa-instagram:focus {
      background: #cd1286;
    }

    a.sociali-fa-facebook:hover,
    a.sociali-fa-facebook:focus {
      background: #3b5999;
    }

    a.sociali-fa-linkedin:hover,
    a.sociali-fa-linkedin:focus {
      background: #0077b5;
    }

    a.sociali-fa-youtube:hover,
    a.sociali-fa-youtube:focus {
      background: #eb0206;
    }
  }

  .footer-line {
    border-top: 1px solid #ffffff52;
    margin: 0rem -6rem;

    @media (min-width: 320px) and (max-width: 820px) {
      margin: 0rem;
    }
  }
}
