/// Footer desktop
@import './mediaQuery.scss';
.footers {
  background-color: #011633;
  // margin-top: 13%;
  margin-top: 13%;
  height: auto;

  .newsletter {
    background-color: #fff;
    height: 193px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-radius: 12px;
    margin-top: -70px;
    position: absolute;
    box-shadow: 0px 5px 25px 0px #0116333a;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title-description {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 53px;
      margin-top: -55px;
      img {
        height: 33px;
        @media (min-width: 1281px) and (max-width: 1325px) {
          margin-bottom: -3px;
        }
      }
      .heading {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          padding-top: 43px;
          margin-left: 7px;
          padding-bottom: 14px;
        }

        p {
          margin-top: -34px;
          // width: 434px;
          margin-left: 8px;
          font-family: 'Jost', sans-serif;
        }
      }
    }

    .mail-input {
      margin-left: 12%;
      margin-right: 53px;
      display: flex;
      flex-direction: row;
      @include responsive(big-desktop) {
        margin-left: 41%;
      }
      input {
        height: 44px;
        font-size: 16px;
        font-family: 'Jost', sans-serif;
        width: 13em;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid rgba(0, 0, 0, 0.322);
        text-transform: lowercase;
        padding-left: 33px;
        @include responsive(phone) {
          width: 16em;
        }
      }

      input:focus {
        outline: none !important;
        border: 1px solid orangered;
      }

      button {
        padding: 16.9px 40px 16.9px 40px;
        font-family: 'Montserrat', Sans-serif;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        background-color: #ff5722;
        border-radius: 0px 3px 3px 0px;
        border: none;
        margin-left: -2px;
        cursor: pointer;
        width: 123px;
        margin-top: 0.2px;
      }
    }
  }
  .footer-links {
    padding-top: 253px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .other-pages {
      margin-left: 103px;
      .title {
        color: #fff;
        font-family: 'Montserrat', Sans-serif;
        font-size: 23px;
        font-weight: 500;
      }

      ul {
        list-style: none;
        margin-left: -39px;
        li {
          margin-top: 12px;
          a {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.753);
          }
          a:hover {
            color: orangered;
          }
        }
      }
    }
  }

  .line {
    background-color: rgba(192, 192, 192, 0.562);
    height: 1px;
    width: 100%;
    margin-top: 43px;
    margin-left: -2px;
  }

  .footer-credits {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    img {
      height: 35px;
      margin-top: 17px;
      margin-left: 127px;
    }
    p {
      color: rgba(255, 229, 229, 0.548);
      font-size: 14px !important;
      margin-left: 60px;
      margin-top: 23px;
      a {
        color: orangered;
        text-decoration: none;
      }
    }

    .credits {
      margin-left: 93px;
    }
  }
  @media (min-width: 1024px) and(max-width:1280px) {
    margin-top: 18%;
  }
  @media (min-width: 1024px) and(max-width:1025px) {
    margin-top: 23%;
  }
}

.m-footers {
  background-color: #011633;
  // height: 546px;
  padding-bottom: 23px;
  width: 100vw;
  margin-top: 33%;
  @media (min-width: 350px) and (max-width: 992px) {
    margin-top: 20%;
  }
  .newsletter {
    background-color: #fff;
    height: 193px;
    width: 95%;
    transform: scale(0.8);
    margin-left: -16px;
    padding-left: 13px;
    padding-right: 45px;
    padding-bottom: 27px;
    border-radius: 12px;
    margin-top: -70px;
    position: absolute;
    box-shadow: 0px 5px 25px 0px #0116333a;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title-description {
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin-left: 53px;
      margin-top: -85px;
      transform: scale(0.8);
      @media (min-width: 350px) and (max-width: 992px) {
        margin-top: 0;
      }
      img {
        height: 33px;
        padding-top: 0;
      }
      .heading {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          padding-top: 3.8em;
          margin-left: 7px;
          @media (min-width: 350px) and (max-width: 992px) {
            padding-top: 0;
          }
        }

        p {
          margin-top: -14px;
          width: 25em;
          margin-left: 8px;
        }
      }
    }

    .mail-input {
      margin-left: 16%;
      display: flex;
      flex-direction: row;
      margin-top: -1.5em;
      input {
        height: 44px;
        width: 102%;
        font-size: 16px;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid rgba(0, 0, 0, 0.322);
        text-transform: lowercase;
        padding-left: 15px;
      }

      input:focus {
        outline: none !important;
        border: 1px solid orangered;
      }

      button {
        padding: 16.9px 40px 16.9px 40px;
        font-family: 'Montserrat', Sans-serif;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        background-color: #ff5722;
        border-radius: 0px 3px 3px 0px;
        border: none;
        margin-left: -2px;
        cursor: pointer;
        width: 123px;
        margin-top: 0.2px;
      }
    }
  }
  .footer-links {
    padding-top: 173px;
    margin-left: -33%;
    .other-pages {
      margin-left: 139px !important;
      .title {
        width: 90%;
        color: #fff;
        font-family: 'Montserrat', Sans-serif;
        font-size: 1.2em;
        font-weight: 600;
      }
      .content {
        font-family: 'Jost', sans-serif;
        i {
          padding: 1em 0.5em 0;
        }
        color: rgb(218, 216, 216);
      }

      ul {
        list-style: none;
        margin-left: -39px;
        li {
          margin-top: 12px;
          a {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.753);
          }
          a:hover {
            color: orangered;
          }
        }
      }
    }
    .downloadTheApp {
      margin-left: 139px !important;
      .header {
        padding-top: 1em;
        color: orangered;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 18px;
      }
      .para {
        font-size: 0.8em;
        color: white;
        font-family: 'Jost', sans-serif;
        padding-top: 1em;
      }
      .buttons {
        width: 100%;
        padding: 1em 0;
        .playStore {
          margin: 0 auto;
          width: 200px;
          overflow: hidden;
          img {
            height: auto;
            width: 100%;
          }
        }
        .appStore {
          margin: 0 auto;
          overflow: hidden;
          width: 180px;
          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }

  .line {
    background-color: rgba(192, 192, 192, 0.562);
    height: 1px;
    width: 100%;
    margin-top: 23px;
    margin-left: -2px;
  }

  .footer-credits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 55px;
      margin-top: 37px;
    }
    p {
      color: rgba(255, 229, 229, 0.548);
      font-size: 14px !important;

      margin-top: 23px;
      a {
        color: orangered;
        text-decoration: none;
      }
    }
  }
}

.vl {
  list-style-type: none;
  li {
    font-family: 'Jost', sans-serif;
    color: #fff;
    padding: 0.5em;
    font-size: 1.3em;
    font-weight: 500;
    &::before {
      content: '\2014';
      position: absolute;
      margin-left: -20px;
    }
  }
}

.follow-us {
  display: flex;

  flex-direction: row;
  align-items: center;
  align-self: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  .title span {
    color: #fff;
  }

  i {
    cursor: pointer;
    color: #ff5722;
    font-size: 17px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
