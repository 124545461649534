@import '../styles/mediaQuery.scss';
.shah-message {
  background-color: #ebf3fe;
  padding: 5em 0;
  margin-top: 2rem;
  .container {
    display: flex;
    .photo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;
      position: relative;
      .photo {
        height: auto;
        width: 100%;
        position: relative;
        @include responsive(big-desktop) {
          height: auto;
        }
        img {
          height: auto;
          // padding-left: 5em;
          width: 100%;
          border-radius: 60px 0px 60px 0px;
        }
      }
      .organge-box:hover {
        background-color: #a7320e;
      }
      .organge-box {
        height: 220px;
        width: 178px;
        background-color: #ff5722;
        position: absolute;
        border-radius: 30px 0px 30px 0px;
        bottom: 3px;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        @include responsive(big-desktop) {
          padding: 1em;
          margin-top: 700px;
          margin-left: 592px;
        }
        @media only screen and (max-width: 992px) {
          position: inherit;
          margin-top: -80px;
        }

        .badge-icon {
          height: 35px;
          width: 35px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          margin-top: 23px;
        }
        .number {
          color: #ffffff;
          font-family: 'IBM Plex Sans', Sans-serif;
          font-size: 32px;
          font-weight: 600;
          margin-bottom: -12px;
          /* margin-top: 0px; */
          margin-bottom: -3px;
          @include responsive(big-desktop) {
            font-size: 50px;
          }
        }
        .badge-title {
          color: #fff;
          width: 82%;
          font-family: 'Jost', Sans-serif;
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          @include responsive(big-desktop) {
            font-size: 18px;
          }
          /* line-height: 1.3; */
          /* margin-left: 6px; */
          /* margin-right: 6px; */
        }
      }
    }
    .content {
      padding: 0 4em;
      width: 100%;
      .all-text {
        @include responsive(big-desktop) {
          width: 85%;
        }
        .sub-header {
          margin-top: 0em;
          font-family: 'Montserrat', sans-serif;
        }
        p {
          text-align: justify;
          font-family: 'Jost', sans-serif;
        }
      }
    }
  }
}
.phone-container {
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .photo {
    // height: 395px;
    width: 20em;
    img {
      height: 100%;
      width: 100%;
      border-radius: 60px 0px 60px 0px;
    }
  }
  .organge-box {
    height: 135px;
    width: 140px;
    background-color: #ff5722;
    position: absolute;
    border-radius: 30px 0px 30px 0px;
    margin-top: 216px;
    margin-left: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .badge-icon {
      margin-top: 12px;
    }
    .number {
      color: #ffffff;
      font-family: 'IBM Plex Sans', Sans-serif;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: -12px;
      margin-top: 1px;
      margin-bottom: -5px;
    }
    .badge-title {
      color: #fff;
      width: 82%;
      font-family: 'Jost', Sans-serif;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      margin-top: 6px;
    }
  }
}
.mobile-be-the-homeopath {
  margin-top: 3em;
  background-color: #011633;
  padding: 3em 0;
  .combine {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .headMain {
      text-align: center;
      .headings {
        h2 {
          font-family: 'Montserrat', sans-serif;
          color: white;
          @include responsive(tab-port) {
            font-size: 32px;
          }
          @include responsive(phone) {
            font-size: 24px;
          }
          span {
            color: #ff5722;
          }
        }
      }
      button {
        font-family: 'Montserrat', Sans-serif;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height: 1.5;
        color: #ffffff;
        background-color: #ff5722;
        border-radius: 3px 3px 3px 3px;
        padding: 15px 35px 15px 35px;
        cursor: pointer;
        border: none;
        margin-top: 12px;
      }
    }
    .photo {
      border-radius: 60px 0px 60px 0px;
      width: 80%;
      overflow: hidden;
      @include responsive(tab-port) {
        width: 60%;
      }
      @include responsive(phone) {
        width: 80%;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  ul.vl {
    padding: 0;
  }
}
