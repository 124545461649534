.grid {
  padding: 15px 123px 0px 123px;
  margin-left: 25px;

  @media (min-width: 1250px) and (max-width: 1280px) {
    padding: 123px 123px 0px 40px;
  }

  @media (min-width: 1024px) and (max-width: 1250px) {
    padding: 123px 123px 0px 40px;
  }

  .title {
    font-size: 24px;
    font-family: 'Montserrat', Sans-serif;
    margin-left: 1.5%;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 97%;
    position: relative;

    @media (min-width: 1200px) and (max-width: 1280px) {
      // margin-left: 0%;
      margin-left: 4%;
      // height: 300px;
      // width: 258px;
    }

    @media only screen and (max-width: 1280px) {
      width: 102%;
    }

    @media only screen and (min-width: 1597px) {
      width: 91%;
    }

    @media only screen and (min-width: 1700px) {
      width: 92%;
    }

    .heading {
      font-size: 14px;
      margin-left: 42px;
    }

    .button {
      font-family: 'Montserrat', Sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      fill: #fff;
      color: #fff;
      background-color: #ff5722;
      border: 1px solid #ff5722;
      border-radius: 3px 3px 3px 3px;
      padding: 15px 30px 15px 30px;
      cursor: pointer;
      // margin-left: 29%;
      position: absolute;
      right: 0;

      @media (min-width: 1250px) and (max-width: 1280px) {
        right: 70px;
      }
    }

    .button:hover {
      color: #ff5722;
      background-color: #fff;
    }
  }

  .courses {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    place-items: center;
    align-items: stretch;
  }

  .newCourse {
    margin-left: 0%;
    margin-top: 0px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;

    @media (min-width: 1250px) and (max-width: 1279px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @media (min-width: 350px) and (max-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  .newCourse-2 {
    margin-left: 0%;
    margin-top: 0px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;

    @media (min-width: 1250px) and (max-width: 1279px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @media (min-width: 350px) and (max-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.grid-1 {
  background: rgba(251, 251, 251, 1);

  padding: 2rem;

  .title {
    font-size: 24px;
    font-family: 'Montserrat', Sans-serif;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;

    h2 {
      span {
        color: #ff5722;
        margin-left: 10px;
      }
    }

    .heading {
      font-size: 14px;
      margin-left: 42px;
    }

    .button {
      font-family: 'Montserrat', Sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      fill: #fff;
      color: #fff;
      background-color: #ff5722;
      border: 1px solid #ff5722;
      border-radius: 3px 3px 3px 3px;
      padding: 15px 30px 15px 30px;
      cursor: pointer;
      // margin-left: 29%;
      position: absolute;
      right: 0;

      @media (min-width: 1250px) and (max-width: 1280px) {
        right: 70px;
      }
    }

    .button:hover {
      color: #ff5722;
      background-color: #fff;
    }
  }

  .courses {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    place-items: center;
    align-items: stretch;
  }

  .newCourse-1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    place-items: center;
    align-items: stretch;
  }
}

.mobile-grid {
  margin-top: 83px;

  .title {
    font-family: 'Montserrat', Sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      font-size: 10px;
      margin-top: -12px;
    }

    .button {
      font-family: 'Montserrat', Sans-serif;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      fill: #fff;
      color: #fff;
      background-color: #ff5722;
      border: 1px solid #ff5722;
      border-radius: 3px 3px 3px 3px;
      padding: 15px 30px 15px 30px;
      cursor: pointer;
    }

    .button:hover {
      color: #ff5722;
      background-color: #fff;
    }
  }

  .courses {
    margin-left: 2%;
    margin-top: 93px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;

    @media (min-width: 821px) and (max-width: 992px) {
      justify-content: space-evenly;
      padding: 0px 13px;
      margin: 0px;
    }

    @media (min-width: 768px) and (max-width: 820px) {
      justify-content: space-evenly;
      padding: 0px 13px;
      margin: 0px;
    }

    @media (min-width: 400px) and (max-width: 767px) {
      justify-content: center;
      padding: 0px 17px;
      margin: 0px;
    }

    @media (min-width: 390px) and (max-width: 400px) {
      justify-content: center;
      padding: 0px 5px;
      margin: 0px;
    }

    @media (min-width: 320px) and (max-width: 390px) {
      justify-content: center;
      padding: 0px 5px;
      margin: 0px;
    }
  }

  @media (min-width: 350px) and (max-width: 992px) {
    margin-top: 30px;
  }
}

.mobile-grid-1 {
  background: rgba(251, 251, 251, 1);

  padding: 1rem;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 10px;
    margin-right: 10px;
    h2 {
      span {
        color: #ff5722;
        margin-left: 10px;
      }
    }
    .button-1 {
      font-family: 'Montserrat', Sans-serif;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      fill: #fff;
      color: #fff;
      background-color: #ff5722;
      border: 1px solid #ff5722;
      border-radius: 3px 3px 3px 3px;
      padding: 10px 10px;
      cursor: pointer;
    }

    .button:hover {
      color: #ff5722;
      background-color: #fff;
    }
  }
}
