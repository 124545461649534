@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.model-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;

  .modal-content {
    background-color: #fefefe;
    overflow: auto;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    max-width: 600px;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;

    &:hover,
    &:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }

  table {
    overflow: auto;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    th,
    td {
      overflow: auto;
      border: 1px solid #ccc;
      padding: 10px;
      text-align: left;
      line-height: 20px;
      button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 6px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    th {
      background-color: #f2f2f2;
    }

    tbody tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }

  @media (max-width: 767px) {
    table {
      thead {
        display: none; /* Hide the table header on small screens */
      }

      tbody tr {
        display: block;
        margin-bottom: 20px;

        td {
          display: flex;
          flex-direction: column;
          width: 100%;

          &:before {
            content: attr(data-label); /* Use the data-label attribute content as label */
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
