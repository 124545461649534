@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
@import './mediaQuery.scss';
.desktop-hero {
  height: 25em;
  width: 100vw;
  background-color: orangered;
  position: relative;
  overflow: hidden;
  @media (min-width: 350px) and (max-width: 992px) {
    margin-top: 10px;
  }
  @include responsive(big-desktop) {
    height: 32em;
  }
  @media only screen and (min-width: 2000px) {
    height: 36em;
  }
  img {
    width: 100%;
    height: 100%;
  }

  .content {
    background: #011633c0; /* fallback for old browsers */

    height: 25em;

    @include responsive(big-desktop) {
      height: 32em;
    }
    @media only screen and (min-width: 2000px) {
      height: 36em;
    }
    width: 100vw;
    position: absolute;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 36px;
      color: #fff;
      font-family: 'Montserrat', Sans-serif;
      font-weight: 500;
      text-align: center;
      padding-bottom: 14px;
      width: 65%;
    }

    .breadcrumb {
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span:nth-child(1) {
        cursor: pointer;
      }
      span:nth-child(3) {
        padding-left: 5px;
        font-weight: bold;
      }
      .fa {
        margin-top: 4px;
        margin-left: 5px;
        color: orangered;
      }
    }
  }
}

//Mobile

.mobile-hero {
  height: 7em;
  width: 100vw;
  background-color: orangered;
  position: relative;
  overflow: hidden;
  margin-top: 13px;
  @media only screen and (max-width: 768px) {
    height: 15em;
  }

  @media only screen and (max-width: 441px) {
    height: 8em;
  }
  @media only screen and (max-width: 360px) {
    height: 7em;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .content {
    background: #011633c0; /* fallback for old browsers */

    height: 19em;
    width: 100vw;
    position: absolute;
    top: 0;
    @media only screen and (max-width: 768px) {
      height: 28em;
    }
    @media only screen and (max-width: 441px) {
      height: 21em;
    }

    @media only screen and (max-width: 360px) {
      height: 19em;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 33px;
      transform: scale(0.7);
      color: #fff;
      font-family: 'Montserrat', Sans-serif;
      font-weight: 500;
      margin-top: -53%;
      width: 534px !important;
      text-align: center;
      @media only screen and (min-width: 540px) {
        margin-top: -37%;
      }
      @media only screen and (min-width: 700px) {
        margin-top: -27%;
      }
    }

    .course-mobile-title {
      @media only screen and (max-width: 992px) {
        font-size: 15px;
      }
    }

    .breadcrumb {
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transform: scale(0.7);
      span:nth-child(1) {
        cursor: pointer;
      }
      span:nth-child(3) {
        padding-left: 5px;
        font-weight: bold;
      }
      .fa {
        margin-top: 4px;
        margin-left: 5px;
        color: orangered;
      }
    }
  }
}
