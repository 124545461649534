.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: popupFadeIn 0.3s ease-out;
    max-width: 460px;
    width: 100%;
    border: 2px solid orangered;
}

.popup-content:hover {
    border: 2px solid #0056b3;
}

@keyframes popupFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.popup-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.popup-content button {
    margin: 10px;
    padding: 12px 24px;
    cursor: pointer;
    background-color: orangered;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.popup-content button:hover {
    background-color: #0056b3;
}

/* Media query for larger screens */
@media (min-width: 768px) {
    .popup-content {
        padding: 20px;
    }

    .popup-content h2 {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .popup-content button {
        padding: 15px 30px;
        font-size: 18px;
    }
}
