.alert-box-app {
  position: fixed;
  z-index: 10000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.432);
  .alert-container {
    background-color: #fff;
    height: 110px;
    width: 80%;
    border-radius: 12px;
    padding: 12px;
    position: relative;

    .title {
      font-family: 'Jost', sans-serif;
      font-size: 21px;
      padding-top: 5px;
      padding-left: 5px;
    }

    .paragraph {
      padding-top: 5px;
      padding-left: 5px;
      font-family: 'Jost', sans-serif;
      font-size: 14px;
    }
    .button {
      position: absolute;
      right: 0;
      margin-right: 2em;
      cursor: pointer;
      font-family: 'Jost', sans-serif;
      font-size: 17px;
      text-transform: uppercase;
      margin-top: 13px;
      color: orangered;
    }
  }
}

.alert-box-apps {
  position: fixed;
  z-index: 10000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -55%;
  margin-left: -3%;
  background-color: rgba(0, 0, 0, 0.432);
  .alert-container {
    background-color: #fff;
    height: 110px;
    width: 80%;
    border-radius: 12px;
    padding: 12px;
    position: relative;

    .title {
      font-family: 'Jost', sans-serif;
      font-size: 21px;
      padding-top: 5px;
      padding-left: 5px;
    }

    .paragraph {
      padding-top: 5px;
      padding-left: 1.6em;
      font-family: 'Jost', sans-serif;
      font-size: 14px;
    }
    .button {
      position: absolute;
      right: 0;
      margin-right: 2em;
      cursor: pointer;
      font-family: 'Jost', sans-serif;
      font-size: 17px;
      text-transform: uppercase;
      margin-top: 13px;
      color: orangered;
    }
  }
}

.shah-learnings {
  background-color: #f9fdff;
  overflow-x: hidden;

  #myBtn {
    display: none;
  }

  .navbar {
    display: flex;
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 83px;
    overflow: hidden;
    margin-top: 8px;
    border-bottom: 1px solid rgba(51, 50, 50, 0.39);
    .drawer {
      background: #fff;
      height: 45px;
      width: 45px;
      position: absolute;
      left: 0;
      margin-top: 12px;
      margin-left: 23px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      cursor: pointer;

      span {
        border-bottom: 2px solid #000;

        height: 12px;
        width: 95%;
        padding: 1px;
        margin-top: -7px;

        // background-color: #000;
      }
    }
    .image {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      img {
        height: auto;
        width: 223px;
      }
    }

    .circle {
      background: #000;
      height: 45px;
      width: 45px;
      position: absolute;
      right: 0;
      border-radius: 50%;
      margin-top: 12px;
      margin-right: 23px;
    }
  }

  .content-background {
    background-color: #f9fdff;
    margin-top: -6em;
    .title {
      font-family: 'Jost', sans-serif;
      margin-left: 19px;
      width: 100%;
      font-weight: 500;

      span {
        position: absolute;
        color: orangered;
        right: 0;
        margin-right: 23px;
      }
    }
    .grid-card {
      margin-left: 12px;
      margin-top: 30px;
      overflow-y: hidden;
      overflow-x: auto;
      max-height: 400px;
      margin-bottom: 123px;
      display: flex;

      .card {
        background-color: #fff;
        height: 274px;
        min-width: 297px;
        border-radius: 12px;
        border: 2px solid rgb(182, 179, 179);
        overflow: hidden;
        margin-left: 5px;
        margin-right: 10px;
        .image {
          background-color: #ffffff;
          height: 70%;
          border-radius: 12px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          overflow: hidden;
          img {
            height: auto;
            width: 100%;
          }
        }
        .title-card {
          background-color: #fff;
          font-family: 'Jost', sans-serif;
          font-size: 13px;
          height: 20%;
          overflow: hidden;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 2px;
        }
        .contents {
          background-color: #fff;
          font-family: 'Jost', sans-serif;
          font-size: 13px;
          padding-left: 12px;
          padding-right: 12px;
          margin-top: -7px;
        }
      }
    }
    .grid-card::-webkit-scrollbar {
      width: 0;
    }
  }
}

.app-course-detail {
  // height:100vh;
  width: 100vw;
  // overflow:hidden;

  .content-backgrounds {
    background-color: #f2f6f9;
    margin-top: 0.3em;
    margin-bottom: 2em;
    .title {
      font-family: 'Jost', sans-serif;
      margin-left: 19px;
      width: 100%;
      font-weight: 500;

      span {
        position: absolute;
        color: orangered;
        right: 0;
        margin-right: 23px;
      }
    }
    .grid-card {
      margin-left: 0px;
      margin-top: 10px;
      overflow-y: hidden;
      overflow-x: auto;
      max-height: 320px;
      margin-bottom: 0;
      display: flex;

      .card {
        background-color: #fff;
        height: 274px;
        min-width: 297px;
        border-radius: 12px;
        border: 2px solid rgb(182, 179, 179);
        overflow: hidden;
        margin-left: 5px;
        margin-right: 10px;
        @media (min-width: 350px) and (max-width: 540px) {
          height: 300px;
          min-width: 275px;
        }
        .image {
          background-color: #ffffff;
          // height: 70%;
          border-radius: 12px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          overflow: hidden;
          @media (min-width: 320px) and (max-width: 821px) {
            // height: 50% !important;
          }
          img {
            height: auto;
            width: 100%;
          }
        }
        .title-card {
          background-color: #fff;
          font-family: 'Jost', sans-serif;
          font-size: 13px;
          // height: 20%;
          overflow: hidden;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 2px;
          font-weight: 400;
          // @media (min-width: 350px) and (max-width: 540px) {
          //   height: 24%;
          // }
        }
        .contents {
          background-color: #fff;
          font-family: 'Jost', sans-serif;
          font-size: 13px;
          padding-left: 12px;
          padding-right: 12px;
          margin-top: 0px;
          font-weight: 400;
        }
      }
    }
    .grid-card::-webkit-scrollbar {
      width: 0;
    }
  }
  .course-video {
    height: 280px;
    z-index: 9;
    position: fixed;
    overflow: hidden;
    width: 100%;
    @media (min-width: 821px) and (max-width: 992px) {
      height: auto;
    }
    @media (min-width: 567px) and (max-width: 821px) {
      height: auto;
    }
    @media (min-width: 320px) and (max-width: 567px) {
      height: auto;
    }
    .back-btn {
      position: absolute;
      font-size: 18px;
      left: 0;
      margin-top: 12px;
      margin-left: 0.8em;
      z-index: 100;
    }
    .video {
      // height: 100%;
      width: 100%;
      // background-color: #f8c6ed;

      // iframe {
      //   position: relative !important;
      // }

      // .vc-container {
      //   position: relative !important;
      // }
      .container-second {
        height: 626px !important;
        @media (min-width: 891px) and (max-width: 912px) {
          height: 604px !important;
        }
        @media (min-width: 821px) and (max-width: 890px) {
          height: 582px !important;
        }
        @media (min-width: 769px) and (max-width: 820px) {
          height: 493px !important;
        }
        @media (min-width: 514px) and (max-width: 768px) {
          height: 460px !important;
        }
        @media (min-width: 401px) and (max-width: 540px) {
          height: 360px !important;
        }
        @media (min-width: 413px) and (max-width: 420px) {
          height: 275px !important;
        }
        @media (min-width: 400px) and (max-width: 412px) {
          height: 272px !important;
        }
        @media (min-width: 390px) and (max-width: 399px) {
          height: 258px !important;
        }
        @media (min-width: 361px) and (max-width: 389px) {
          height: 251px !important;
        }
        @media (min-width: 320px) and (max-width: 360px) {
          height: 240px !important;
        }
      }

      img {
        height: auto;
        width: 100%;
      }
      .black-screen {
        height: 100%;
        width: 100%;
        background-color: rgba(240, 231, 228, 0.384);
        position: absolute;
      }
    }
  }

  .app-course-detail-content {
    height: 100%;
    background-color: aqua;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 66%;
    font-family: 'Jost', sans-serif;
    background-color: #f2f6f9;
    @media (min-width: 567px) and (max-width: 820px) {
      padding-top: 60%;
    }
    // @media (min-width: 394px) and (max-width: 414px) {
    //   padding-top: 62%;
    // }
    // @media (min-width: 374px) and (max-width: 393px) {
    //   padding-top: 66%;
    // }
    // @media (min-width: 320px) and (max-width: 375px) {
    //   padding-top: 70%;
    // }
    // @media (min-width: 320px) and (max-width: 566px) {
    //   padding-top: 100%;
    // }
    // @media (min-width: 567px) and (max-width: 769px) {
    //   padding-top: 36%;
    // }
    .navigation-app-course-detail {
      position: fixed;
      z-index: 9;
      width: 100%;
      padding-top: 9px;
      border-top: 2px solid rgba(43, 42, 42, 0.226);
      border-bottom: 2px solid rgba(43, 42, 42, 0.226);
      padding-bottom: 12px;
      background-color: #f2f6f9;
      .tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-weight: 600;
        padding: 0.1rem 0;
        line-height: 15px;
        background-color: #f2f6f9;

        .tab {
          padding-left: 0%;
          padding-right: 0%;
          text-transform: uppercase;
          font-size: 15px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .border {
            height: 2px;
            width: 61px;
            background: orangered;
            position: absolute;
            margin-top: 2.7em;

            @media not all and (min-resolution: 0.001dpcm) {
              @supports (-webkit-appearance: none) and (stroke-color: transparent) {
                margin-top: 1.3em;
              }
            }
          }
        }

        .active-tab {
          color: orangered;
        }
      }
    }
    .app-course-title {
      padding-top: 4.5em;
      width: 350px;
      font-weight: bold;
      margin-left: 1em;
      margin-right: 2.4em;
      font-size: 16px;
      @media (min-width: 567px) and (max-width: 821px) {
        width: auto;
      }
      @media (min-width: 320px) and (max-width: 821px) {
        width: auto;
        padding-top: 3.5em;
      }

      @media only screen and (min-width: 999px) {
        width: 94%;
        margin-right: 1em;
      }
      // @media (min-width: 320px) and (max-width: 567px) {
      //   padding-top: 2.5em;
      // }
    }
    .app-course-index-contents {
      margin-left: 1em;
      margin-right: 2.4em;
      font-size: 14px;
      margin-top: 12px;
    }

    .pricing {
      // margin-left: -1em;
      margin-top: 23px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .price-box {
        background-color: #eaeaea;
        border: 1px solid orangered;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 225px;
        margin-left: 12px;
        margin-right: 12px;
        overflow: hidden;
        .title {
          font-size: 17px;
          border-bottom: 1px solid orangered;
          width: 100%;
          text-align: center;
          padding-bottom: 9px;
          text-transform: uppercase;
        }
        .price-tabel {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .actual-price {
            font-size: 18px;
            font-weight: bold;
            margin-top: 12px;
          }

          .market-price {
            font-size: 15px;
            text-decoration: line-through;
          }
        }
      }
    }

    .active-pricing {
      background-color: #021633 !important;
      color: #fff;
    }
    .error-pricing {
      // background-color: #021633 !important;
      color: red;
    }
    .app-line {
      width: 100%;
      height: 3px;
      background-color: #e5e5e5;
      margin-top: 2em;
    }
    .app-course-overview {
      margin-top: 2em;
      margin-left: 1em;
      z-index: -1;
      .title {
        font-size: 19px;
        font-weight: bold;
        opacity: 0.6;
      }
      .contents {
        margin-top: 1em;
      }
    }

    .download-app-curriculum {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2em;

      .title {
        text-decoration: none;
        border: 2px solid orangered;
        border-radius: 9px;
        color: #000;
        padding-left: 53px;
        padding-right: 53px;
        padding-top: 13px;
        padding-bottom: 13px;
        font-size: 25px;
      }
    }

    .feature-course-app {
      margin-top: 2em;
      margin-left: 1em;
    }

    .app-tutor {
      margin-top: 2em;
      margin-left: 1em;
      margin-right: 1em;
      display: flex;
      flex-direction: column;

      .instructor {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        border-radius: 2px;
        margin-top: 5px;
        margin-bottom: 5px;
        .image {
          background-color: #000;
          height: 50px;
          width: 50px;
          border-radius: 50%;
          overflow: hidden;
          img {
            height: auto;
            width: 100%;
          }
        }
        .contents {
          width: calc(100% - 50px);
          margin-left: 9px;
          .title {
            font-size: 18px;
          }
          .sub-title {
            font-size: 14px;
            margin-top: -4px;
          }
        }
      }
    }
    .related-course-app {
      height: 420px;
      margin-top: 2em;
      margin-left: 1em;
      .title {
        font-size: 19px;
        font-weight: bold;
        opacity: 0.6;
      }
    }
    .app-course-buy-button {
      background-color: #e5e5e5;
      position: fixed;
      bottom: 0;
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 12px;
      padding-top: 12px;
      .btn {
        background-color: orangered;
        color: #fff;
        width: 68%;
        height: 50px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 23px;
        @media (min-width: 320px) and(max-width:992px) {
          width: 90%;
          height: 35px;
        }
      }
    }
  }
}

.app-index-curriculum {
  width: 90vw !important;
  .title {
    font-weight: 400;
    margin-bottom: 12px;
    @media (min-width: 320px) and(max-width:567px) {
      margin-bottom: 0px;
    }
  }
  .course-contents {
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
  }
  .lesson {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    .play-btn {
      font-size: 23px;
    }
    .title {
      margin-left: 13px;
    }
  }
}

.app-index-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw !important;

  margin-left: 0px !important;
  padding-left: 0px;
  .review-card {
    width: 90%;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgba(146, 143, 143, 0.24);
    height: 190px;
    border-radius: 12px;

    overflow: hidden;

    .review-card-container {
      padding: 10px;
      .top-row {
        display: flex;
        flex-direction: row;

        //   justify-content: center;
        align-items: center;

        .image {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-color: #000;
        }
        .author {
          margin-left: 12px;
          .author-name {
            font-size: 18px;
          }
          .author-category {
            font-size: 13px;
            font-weight: 400;
            margin-top: -3px;
          }
        }
        .rating {
          right: 0;
          margin-right: 23px;
          font-weight: 400;
          color: orangered;
          margin-left: 33%;
        }
      }

      .bottom {
        p {
          font-weight: 400;
          width: 100%;
          margin-left: 3%;
        }
      }
    }
  }
}
